import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/image.png';
import visualization2 from '../images/Screenshot 2024-07-11 121423.png';



const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <p>
          This project is an automated system I developed to interact with Quora and Reddit using Selenium WebDriver with Python. The primary purpose of this project is to automate repetitive tasks such as upvoting posts and managing accounts, which can significantly boost social media interactions and enhance SEO efforts.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="Quora Upvotes" style={{ width: '20%', height: 'auto' }} />
            <img src={visualization2} alt="Quora Upvotes" style={{ width: '40%', height: 'auto' }} />

          </div>
        <p>
          The script is designed to handle various actions including signing in, creating new accounts, and upvoting posts on both Quora and Reddit. It navigates these websites, interacts with web elements, and performs the desired actions while handling dynamic content and potential errors gracefully. By automating these tasks, the project aims to save time and increase efficiency in managing social media activities.
        </p>
        <p>
          I created this project to streamline social media management tasks and to demonstrate the capabilities of web automation in increasing online engagement. The ability to automatically upvote posts and manage accounts showcases my skills in handling complex web interactions and automating processes that would otherwise require significant manual effort. This project has been particularly effective in increasing interactions and boosting SEO, making it a valuable tool for online marketing strategies.
        </p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>
          The automated system offers a range of features designed to simplify and enhance interactions with Quora and Reddit. It includes automated sign-in and account creation processes, which are essential for managing multiple accounts or handling new user registrations efficiently. This functionality ensures that users can quickly and easily create and manage accounts without manual intervention.
        </p>
        <p>
          A key feature is the upvoting functionality, which automates the process of finding and upvoting posts. This is particularly useful for increasing the visibility and engagement of specific content. The script can scroll through pages, locate upvote buttons, and interact with them while managing timing and delays to mimic human behavior. This helps in boosting the popularity of posts and increasing interactions on these platforms.
        </p>
        <p>
          The system is built to handle dynamic web elements using multiple locators (XPATH, ID, and NAME) and incorporates error handling and retry mechanisms. This ensures that the script can adapt to changes in the web page structure and continue functioning reliably. Additionally, the use of incognito mode and various browser options helps maintain privacy and avoid caching issues.
        </p>
        <p>
          Overall, these features work together to provide a robust and flexible solution for automating social media interactions on Quora and Reddit, making it an effective tool for enhancing online presence and SEO efforts.
        </p>
      </>
    )
  },
  {
    id: 'technology-used',
    title: 'Technology Used',
    content: (
      <>
        <p>
          This project leverages a variety of technologies to achieve its goals:
        </p>
        <p>
          <strong>Python</strong>: The core programming language used for scripting, chosen for its simplicity and extensive libraries.
        </p>
        <p>
          <strong>Selenium WebDriver</strong>: A tool for automating web browser interactions. It enables the script to control the browser and perform tasks such as clicking buttons, filling out forms, and scrolling through pages.
        </p>
        <p>
          <strong>ChromeDriver</strong>: A standalone server that implements WebDriver's wire protocol for Chromium, allowing the script to control the Chrome browser.
        </p>
        <p>
          <strong>HTML and CSS Selectors</strong>: Fundamental web technologies used to identify and interact with elements on the webpage.
        </p>
        <p>
          <strong>XPath</strong>: A powerful language for navigating through elements and attributes in an XML document, used here to locate elements on the webpage.
        </p>
        <p>
          <strong>Selenium WebDriver Common Libraries</strong>: Including By, Keys, Select, and WebDriverWait, these libraries facilitate interactions with web elements and handle asynchronous page loads.
        </p>
        <p>
          <strong>BeautifulSoup</strong>: A library for parsing HTML and XML documents, used to scrape data from web pages.
        </p>
        <p>
          <strong>Requests</strong>: A simple HTTP library for Python, used to make network requests.
        </p>
        <p>
          These technologies work together to create a robust and flexible system for automating interactions on Quora and Reddit, ensuring the script can handle complex web interactions and automate processes reliably.
        </p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>
          Developing this automated system came with its set of challenges. One significant challenge was dealing with the dynamic nature of web elements on Quora and Reddit. These websites frequently update their layouts and element attributes, which required implementing multiple locators (such as XPATH, ID, and NAME) to ensure consistent element identification. Maintaining the script's reliability in the face of these changes was crucial for its successful operation.
        </p>
        <p>
          Handling timing issues associated with web automation was another challenge. Elements on the webpage often took varying amounts of time to load, necessitating the use of sleep intervals, explicit waits, and retry mechanisms. This ensured that the script could wait for elements to become interactable, reducing the risk of errors due to elements not being ready for interaction.
        </p>
        <p>
          Error handling was a critical aspect of the project. The script needed to manage exceptions gracefully and retry actions until successful. This involved extensive testing and debugging to identify and address potential failure points. For instance, the upvoting process required several attempts with different locators and methods to ensure that the script could handle various scenarios and fallbacks effectively.
        </p>
        <p>
          Additionally, incorporating incognito mode and avoiding cached data added another layer of complexity. Ensuring that each browser session was fresh helped in dealing with session-specific issues but also required careful management of browser options and settings. Overall, these challenges provided valuable learning experiences in web automation, error handling, and creating robust, adaptable scripts capable of performing reliably in a dynamic environment.
        </p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>
          In conclusion, this automated system for interacting with Quora and Reddit demonstrates the power and potential of web automation to enhance social media engagement and boost SEO efforts. By leveraging Python and Selenium WebDriver, I created a robust solution that effectively navigates the complexities of these websites to perform repetitive tasks such as upvoting posts and managing accounts.
        </p>
        <p>
          The project was not just a technical achievement but also a practical solution for increasing online interactions and visibility. It showcased my ability to tackle real-world problems with innovative automation techniques, handling complex web interactions and dynamic content gracefully. The skills and knowledge gained from this project, including dynamic element handling, error management, and timing control, have been instrumental in my development as a programmer.
        </p>
        <p>
          Moving forward, the system has the potential for further enhancements and scalability. It could be adapted to work with other social media platforms or expanded with features like automated comment posting or integration with analytics tools. These next steps could make the tool even more versatile and powerful, providing greater convenience and effectiveness for users.
        </p>
        <p>
          Overall, this project highlights the intersection of technology and practical problem-solving, demonstrating how automation can significantly improve efficiency and create unique opportunities in the realm of social media management and online marketing.
        </p>
      </>
    )
  }
];

const ProfileBooster = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default ProfileBooster;
