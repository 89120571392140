import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import { useNavigate } from 'react-router-dom';
import { BuildingContext } from '../BuildingContext';
import visualization1 from '../images/output.png';



const CarValueFinder = ({ onUnlockMovement }) => {
  const navigate = useNavigate();
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function
  const { setBuilding } = useContext(BuildingContext);

  // Function to navigate to project11 and pass the state
  const navigateToProject11 = (event) => {
    event.preventDefault();
    const buildingData = {
      info: 'Web Automation and Data Extraction Building',
      path: '/projects/CarDataCompiler',
      exit: { x: 4, z: 0.5 },
      receptionistMessage: 'Welcome to the Web Automation building! Here you will find information on all of my projects related to automation of the web.',
      projects: [
        { name: 'OpenTable Reservation Bot', path: '/projects/OpenTableReservationBot' },
        { name: 'Social Media Profile Booster', path: '/projects/ProfileBooster' },
        { name: 'Car Data Compiler', path: '/projects/CarDataCompiler' }
      ]
    };
    setBuilding(buildingData);  // Set building data
    navigate('/projects/CarDataCompiler');
  };

  const sections = [
    {
      id: 'description',
      title: 'Description',
      content: (
        <>
          <p>
            This project focuses on analyzing vehicle listings to identify undervalued cars using historical data and statistical analysis. The primary goal is to combine data from multiple SQLite databases, calculate rolling averages for price and price per mile (PPM), and use these benchmarks to identify and filter undervalued cars in a given dataset.
          </p>
          <p>
            The process begins by combining data from multiple database files into a single Pandas DataFrame. The data is then cleaned, ensuring that key columns such as year, odometer, and price contain valid numeric values. Rolling averages for price and PPM are calculated, providing benchmarks for evaluating new listings.
          </p>
          <p>
            Next, the project applies these benchmarks to a new dataset, comparing each vehicle's price and PPM against expected values based on similar cars from the historical data. This comparison helps identify cars that are priced below their expected market value, indicating potential undervalued deals.
          </p>
          <p>
            By leveraging historical data and statistical methods, this project aims to provide insights into the used car market and assist in identifying good investment opportunities. The results are outputted as a CSV file, making it easy to review and analyze the top undervalued deals.
          </p>
          <p>
            For collecting vehicle listings from Facebook Marketplace, this project integrates data from the <a href="/project11" onClick={navigateToProject11}>scraping project</a> which automates the data collection process.
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="Prediction based on year and odometer" style={{ width: '45%', height: 'auto' }} />
          </div>
        </>
      )
    },
    {
      id: 'features',
      title: 'Features',
      content: (
        <>
          <p>
            This project offers several features designed to analyze vehicle listings and identify undervalued cars:
          </p>
          <p>
            <strong>Data Combination:</strong> The project combines data from multiple SQLite database files into a single DataFrame. This allows for a comprehensive analysis of historical vehicle listings from various sources.
          </p>
          <p>
            <strong>Data Cleaning and Validation:</strong> Ensures that critical fields such as year, odometer, and price are numeric and valid. The project handles missing or invalid data, ensuring a robust dataset for analysis.
          </p>
          <p>
            <strong>Rolling Averages Calculation:</strong> Calculates rolling averages for price and price per mile (PPM) using a specified window size. These rolling averages serve as benchmarks for evaluating new vehicle listings.
          </p>
          <p>
            <strong>Outlier Removal:</strong> Implements outlier detection and removal based on interquartile range (IQR) to ensure that the calculated rolling averages are not skewed by extreme values.
          </p>
          <p>
            <strong>Benchmark Comparison:</strong> Compares new vehicle listings against the historical rolling averages to identify undervalued cars. This involves calculating the expected price and PPM for each new listing based on similar cars in the historical data.
          </p>
          <p>
            <strong>Filtering Undervalued Cars:</strong> Filters the new listings to identify those that are undervalued based on their price and PPM. The project highlights cars that are priced below their expected market value, indicating potential good deals.
          </p>
          <p>
            <strong>Results Output:</strong> Outputs the identified undervalued cars to a CSV file, making it easy to review and analyze the top deals. The results include the price difference and PPM difference, providing insights into the potential savings.
          </p>
          <p>
            <strong>Integration with Scraping Project:</strong> References the <a href="/project11" onClick={navigateToProject11}>scraping project</a> for collecting vehicle listings from Facebook Marketplace. The scraping project automates the data collection process, which is then used in this project for analysis and identification of undervalued cars.
          </p>
          <p>
            These features enable a thorough analysis of vehicle listings, leveraging historical data to identify potential investment opportunities in the used car market.
          </p>
        </>
      )
    },
    {
      id: 'technology-used',
      title: 'Technology Used',
      content: (
        <>
          <p>
            This project utilizes a combination of technologies and libraries to analyze vehicle listings and identify undervalued cars:
          </p>
          <p>
            <strong>Python:</strong> The primary programming language used for data manipulation, analysis, and automation.
          </p>
          <p>
            <strong>SQLite:</strong> A lightweight database management system used to store and manage vehicle listings. Multiple SQLite database files are combined to create a comprehensive dataset for analysis.
          </p>
          <p>
            <strong>Pandas:</strong> A powerful data manipulation and analysis library used to handle and process data. Pandas is essential for reading data from SQLite databases, cleaning and validating the data, and performing statistical analysis.
          </p>
          <p>
            <strong>NumPy:</strong> A library for numerical operations, used to handle numerical data and perform calculations such as converting columns to numeric values and handling missing data.
          </p>
          <p>
            <strong>SQL:</strong> Used within SQLite to query and extract data from database files. SQL commands are used to fetch data from the specified tables in the databases.
          </p>
          <p>
            <strong>Statistical Methods:</strong> Implemented using Pandas and NumPy to calculate rolling averages, remove outliers, and identify undervalued cars. These methods include interquartile range (IQR) for outlier detection and rolling median calculations for benchmarking.
          </p>
          <p>
            <strong>Data Integration:</strong> References another project that scrapes vehicle listings from Facebook Marketplace using Selenium and BeautifulSoup. The scraping project automates the data collection process, providing a continuous stream of new listings to analyze.
          </p>
          <p>
            <strong>Data Output:</strong> The results of the analysis are saved to a CSV file using Pandas, making it easy to export and share the identified undervalued cars.
          </p>
          <p>
            These technologies and methods work together to create a robust system for analyzing vehicle listings, identifying undervalued cars, and providing actionable insights into the used car market. By leveraging historical data and advanced statistical methods, the project offers a powerful tool for discovering potential investment opportunities.
          </p>
        </>
      )
    },
    {
      id: 'challenges',
      title: 'Challenges',
      content: (
        <>
          <p>
            Developing this project presented several challenges, which were overcome to ensure a robust and reliable system for analyzing vehicle listings:
          </p>
          <p>
            <strong>Data Integration:</strong> Combining data from multiple SQLite databases was challenging due to variations in the structure and content of the databases. Ensuring consistent schema and handling missing or inconsistent data required careful planning and validation.
          </p>
          <p>
            <strong>Data Cleaning:</strong> Ensuring the integrity and quality of the data involved handling missing values, converting data types, and removing invalid entries. This was crucial for accurate analysis and reliable results.
          </p>
          <p>
            <strong>Outlier Detection:</strong> Identifying and removing outliers from the dataset was necessary to ensure that the rolling averages were not skewed by extreme values. Implementing the interquartile range (IQR) method and fine-tuning the parameters required extensive testing and validation.
          </p>
          <p>
            <strong>Rolling Averages Calculation:</strong> Calculating rolling averages for price and price per mile (PPM) involved handling various window sizes and ensuring accurate calculations. Ensuring that the rolling averages were representative of the underlying data was critical for meaningful benchmarks.
          </p>
          <p>
            <strong>Benchmark Comparison:</strong> Comparing new vehicle listings against historical benchmarks required efficient data processing and filtering. Ensuring that the comparisons were accurate and reliable involved handling edge cases and ensuring the integrity of the calculations.
          </p>
          <p>
            <strong>Performance Optimization:</strong> Processing large datasets and performing complex calculations required optimization to ensure efficient performance. This involved optimizing SQL queries, data processing workflows, and memory management.
          </p>
          <p>
            <strong>Integration with Scraping Project:</strong> Ensuring seamless integration with the scraping project involved handling variations in data formats and ensuring consistent data structures. This required coordination and validation to ensure that the data collected by the scraper could be accurately processed and analyzed.
          </p>
          <p>
            <strong>Handling Dynamic Market Conditions:</strong> The used car market is dynamic, with prices and trends changing rapidly. Ensuring that the analysis remained relevant and accurate required regular updates to the historical data and recalibration of the benchmarks.
          </p>
          <p>
            These challenges required a combination of technical skills, including data manipulation, statistical analysis, and performance optimization. Overcoming these obstacles provided valuable insights into data integration, cleaning, and analysis, ensuring a robust and reliable system for identifying undervalued cars.
          </p>
        </>
      )
    },
    {
      id: 'conclusion',
      title: 'Conclusion',
      content: (
        <>
          <p>
            In conclusion, this project provides a comprehensive solution for analyzing vehicle listings and identifying undervalued cars using historical data and statistical methods. By combining data from multiple SQLite databases, cleaning and validating the data, and calculating rolling averages for price and price per mile (PPM), the project establishes benchmarks for evaluating new listings. These benchmarks help identify cars that are priced below their expected market value, indicating potential good deals.
          </p>
          <p>
            The project integrates advanced technologies such as Python, SQLite, Pandas, and NumPy to handle data manipulation, statistical analysis, and database management. It also references a scraping project for collecting vehicle listings from Facebook Marketplace, ensuring a continuous stream of new data for analysis.
          </p>
          <p>
            Overcoming challenges related to data integration, cleaning, outlier detection, and performance optimization provided valuable insights into creating a robust and reliable system for data analysis. The project's ability to output results in a structured format (CSV) makes it easy to review and analyze the top undervalued deals.
          </p>
          <p>
            Looking forward, the project can be further enhanced by incorporating more sophisticated machine learning models for price prediction, expanding the data sources to include other online marketplaces, and implementing real-time data analysis and alerts. These improvements will enhance the project's capability to provide actionable insights and support decision-making in the used car market.
          </p>
          <p>
            Overall, this project demonstrates the effective use of data analysis and statistical methods to identify undervalued cars, offering a powerful tool for market analysis and investment decision-making in the automotive industry.
          </p>
        </>
      )
    }
  ];

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default CarValueFinder;
