import * as THREE from 'three';

export const addPerson = (scene) => {
  const person = new THREE.Group();

  const headGeometry = new THREE.SphereGeometry(0.3, 32, 32);
  const headMaterial = new THREE.MeshBasicMaterial({ color: 0xF5CBA7 });
  const head = new THREE.Mesh(headGeometry, headMaterial);
  head.position.set(0, 1.1, 0); // Lowered position
  person.add(head);

  // Add eyes
  const eyeGeometry = new THREE.SphereGeometry(0.05, 32, 32);
  const eyeWhiteMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
  const eyeBlueMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });

  const leftEye = new THREE.Mesh(eyeGeometry, eyeWhiteMaterial);
  leftEye.position.set(-0.1, 0.1, 0.27); // Adjusted position
  head.add(leftEye);

  const rightEye = new THREE.Mesh(eyeGeometry, eyeWhiteMaterial);
  rightEye.position.set(0.1, 0.1, 0.27); // Adjusted position
  head.add(rightEye);

  const pupilGeometry = new THREE.SphereGeometry(0.02, 32, 32);
  const leftPupil = new THREE.Mesh(pupilGeometry, eyeBlueMaterial);
  leftPupil.position.set(-0.1, 0.1, 0.32); // Adjusted position
  head.add(leftPupil);

  const rightPupil = new THREE.Mesh(pupilGeometry, eyeBlueMaterial);
  rightPupil.position.set(0.1, 0.1, 0.32); // Adjusted position
  head.add(rightPupil);

  // Add mouth
  const mouthShape = new THREE.Shape();
  mouthShape.moveTo(-0.1, 0.0); // Adjusted position
  mouthShape.quadraticCurveTo(0, -0.1, 0.1, 0.0); // Adjusted position

  const mouthGeometry = new THREE.ShapeGeometry(mouthShape);
  const mouthMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
  const mouth = new THREE.Mesh(mouthGeometry, mouthMaterial);
  mouth.position.set(0, 0, 0.34); // Adjusted position to bring it out farther
  head.add(mouth);

  const torsoGeometry = new THREE.BoxGeometry(0.5, 0.6, 0.3);
  const torsoMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
  const torso = new THREE.Mesh(torsoGeometry, torsoMaterial);
  torso.position.set(0, 0.5, 0); // Lowered position
  person.add(torso);

  const armGeometry = new THREE.BoxGeometry(0.1, 0.5, 0.1);
  const armMaterial = new THREE.MeshBasicMaterial({ color: 0xF5CBA7 });
  const leftArm = new THREE.Mesh(armGeometry, armMaterial);
  leftArm.position.set(-0.3, 0.5, 0); // Lowered position
  const rightArm = new THREE.Mesh(armGeometry, armMaterial);
  rightArm.position.set(0.3, 0.5, 0); // Lowered position
  person.add(leftArm);
  person.add(rightArm);

  const legGeometry = new THREE.BoxGeometry(0.1, 0.6, 0.1);
  const legMaterial = new THREE.MeshBasicMaterial({ color: 0xF5CBA7 });
  const leftLeg = new THREE.Mesh(legGeometry, legMaterial);
  leftLeg.position.set(-0.15, -0.2, 0); // Lowered position
  const rightLeg = new THREE.Mesh(legGeometry, legMaterial);
  rightLeg.position.set(0.15, -0.2, 0); // Lowered position
  person.add(leftLeg);
  person.add(rightLeg);

  const shortsGeometry = new THREE.BoxGeometry(0.5, 0.3, 0.3);
  const shortsMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
  const shorts = new THREE.Mesh(shortsGeometry, shortsMaterial);
  shorts.position.set(0, 0.05, 0); // Lowered position
  person.add(shorts);

  person.position.set(0, 0, 0); // Initial position at ground level
  scene.add(person);

  // Add event listener to add hat when XP is maxed out
  const addHat = () => {
    const hatGeometry = new THREE.ConeGeometry(0.3, 0.5, 32);
    const hatMaterial = new THREE.MeshBasicMaterial({ color: 0xff1493 }); // Neon pink color
    const hat = new THREE.Mesh(hatGeometry, hatMaterial);
    hat.position.set(0, 1.5, 0); // Position on top of the head
    hat.rotation.set(0, 0, 0); // Make the point face up
    person.add(hat);
  };

  window.addEventListener('maxXPReached', addHat);

  return person;
};

export const animatePerson = (keysPressed, person) => {
  const moveSpeed = 0.15;
  const isMoving = keysPressed.ArrowUp || keysPressed.ArrowDown || keysPressed.ArrowLeft || keysPressed.ArrowRight;

  // Calculate the direction vector
  const direction = new THREE.Vector3();
  if (keysPressed.ArrowUp) direction.z -= 1;
  if (keysPressed.ArrowDown) direction.z += 1;
  if (keysPressed.ArrowLeft) direction.x -= 1;
  if (keysPressed.ArrowRight) direction.x += 1;

  // Normalize the direction vector to get the direction
  if (direction.length() > 0) {
    direction.normalize();

    // Calculate the angle the person should face
    const angle = Math.atan2(direction.x, direction.z);
    person.rotation.y = angle;
  }

  // Move the person in the direction they are facing
  if (keysPressed.ArrowUp && person.position.z > -3) person.position.z -= moveSpeed;
  if (keysPressed.ArrowDown && person.position.z < 3) person.position.z += moveSpeed;
  if (keysPressed.ArrowLeft && person.position.x > -15) person.position.x -= moveSpeed;
  if (keysPressed.ArrowRight && person.position.x < 15) person.position.x += moveSpeed;

  if (isMoving) {
    const time = Date.now() * 0.004; // Increase frequency of the sine wave even more
    person.children[2].rotation.x = Math.sin(time * 5) * 0.5; // left arm
    person.children[3].rotation.x = -Math.sin(time * 5) * 0.5; // right arm
    person.children[4].rotation.x = -Math.sin(time * 5) * 0.5; // left leg
    person.children[5].rotation.x = Math.sin(time * 5) * 0.5; // right leg
  } else {
    person.children[2].rotation.x = 0; // left arm
    person.children[3].rotation.x = 0; // right arm
    person.children[4].rotation.x = 0; // left leg
    person.children[5].rotation.x = 0; // right leg
  }
};
