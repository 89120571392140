import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/Screenshot 2024-07-10 141211.png';
import visualization2 from '../images/Screenshot 2024-07-10 141856.png';



const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <h1>Strategy Consulting Prep</h1>
        <p>The <strong>Strategy Consulting Prep</strong> platform is an innovative web application designed to provide aspiring consultants with a comprehensive, interactive, and immersive preparation experience. This project combines the power of artificial intelligence with practical training to simulate real-life case interviews commonly encountered in top consulting firms such as BCG, McKinsey, and Bain & Company.</p>
        <p>The platform offers various courses tailored to each firm, featuring detailed guides, practice questions, and personalized feedback to enhance the user’s problem-solving and analytical skills. Users can engage with an AI-driven chatbot that mimics the role of an interviewer, providing real-time responses and challenging scenarios to ensure thorough preparation.</p>
        <p>Integrated with modern web technologies, including HTML, CSS, and JavaScript, the platform is designed to be both visually appealing and user-friendly. It includes dynamic elements like interactive forms, responsive navigation, and seamless content delivery to enhance user engagement. Additionally, the mailing list feature keeps users updated with the latest resources and tips to stay ahead in their preparation journey.</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img src={visualization1} alt="Visualization 1: Time series graph showing monthly averages of E. coli MPN and weather variables" style={{ width: '50%', height: 'auto' }} />
          <img src={visualization2} alt="Visualization 2: Dual-axis plot illustrating population growth and E. coli levels in Ann Arbor" style={{ width: '50%', height: 'auto' }} />
        </div>
        <p>Through this project, users can gain confidence and proficiency in tackling complex case interviews, ultimately boosting their chances of securing positions at prestigious consulting firms.</p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>The <strong>Strategy Consulting Prep</strong> platform boasts a range of features designed to provide an immersive and effective preparation experience for aspiring consultants. At the heart of the platform is the AI-driven interview simulation, where users can engage with a sophisticated chatbot that mimics real case interview scenarios. This AI provides instant feedback and tailored responses, helping users develop their problem-solving skills and analytical thinking in a realistic environment.</p>
        <p>Firm-specific courses are another standout feature, offering detailed preparation guides for top consulting firms like BCG, McKinsey, and Bain & Company. These courses are designed to provide users with firm-specific strategies, practice questions, and expert preparation tips, ensuring that they are well-equipped for any interview scenario. Interactive practice sessions further enhance this experience, allowing users to participate in real-time practice with instant feedback to refine their skills.</p>
        <p>The platform's responsive design ensures a visually appealing and user-friendly interface that adapts seamlessly to various devices and screen sizes. Whether accessed on a desktop, tablet, or smartphone, the user experience remains consistent and engaging. Dynamic content delivery enhances this experience, providing smooth navigation and uninterrupted access to all the resources and features available.</p>
        <p>To keep users informed and up-to-date, the platform includes a mailing list subscription feature. By joining the mailing list through an integrated form, users can receive the latest resources, tips, and strategies directly in their inbox. Additionally, the platform encourages user feedback through a dedicated review and feedback system, allowing users to submit their experiences and suggestions to help continuously improve the platform.</p>
        <p>Courses are presented through visually distinct cards, each containing a brief description and a link to more detailed information. This design helps users easily explore and select the most relevant courses for their needs. Beyond the courses, users have access to a comprehensive resource library, filled with articles, blogs, and additional materials to supplement their preparation and keep them informed about industry trends and best practices.</p>
        <p>Finally, the platform offers a personalized user experience, with customized content and practice sessions based on individual performance and preferences. This personalization ensures that each user receives the most relevant and effective preparation, tailored to their unique needs and goals.</p>
      </>
    )
  },
  {
    id: 'technology',
    title: 'Technology Used',
    content: (
      <>
        <p>The <strong>Strategy Consulting Prep</strong> platform utilizes a robust combination of modern web technologies to deliver an engaging and efficient user experience. The foundation of the platform is built using HTML for the structure and content of the web pages, ensuring a semantic and accessible framework.</p>
        <p>For styling, CSS is employed to create a visually appealing and responsive design. This ensures that the platform is not only aesthetically pleasing but also adapts seamlessly to different screen sizes and devices, providing a consistent user experience across desktops, tablets, and smartphones.</p>
        <p>JavaScript is used extensively to add interactivity and dynamic functionality to the platform. It powers the AI-driven chatbot, enabling real-time interaction and feedback during practice interview sessions. Additionally, JavaScript handles form submissions, content updates, and other interactive elements, ensuring smooth and responsive user interactions.</p>
        <p>The platform also leverages external libraries and frameworks to enhance its capabilities. These include libraries for handling form validation, enhancing user interface components, and managing asynchronous data fetching and content updates. This use of modern web technologies ensures that the platform is both powerful and flexible, capable of delivering a high-quality preparation experience for aspiring consultants.</p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>Creating the <strong>Strategy Consulting Prep</strong> platform presented several challenges, both technical and non-technical, which required innovative solutions to ensure a smooth and effective user experience.</p>
        <h3>Technical Challenges:</h3>
        <p><strong>AI Integration:</strong> Developing an AI-driven chatbot capable of simulating real case interviews was a significant technical hurdle. The chatbot needed to provide realistic, context-aware responses and instant feedback, which required sophisticated natural language processing (NLP) algorithms and extensive training data.</p>
        <p><strong>Responsive Design:</strong> Ensuring that the platform was fully responsive across a variety of devices and screen sizes was another challenge. This involved meticulous CSS design and testing to ensure consistent functionality and aesthetics on desktops, tablets, and smartphones.</p>
        <p><strong>Real-Time Interactivity:</strong> Implementing real-time interactivity with JavaScript, such as the dynamic updating of content and handling of user inputs without page reloads, required efficient coding practices and the use of advanced JavaScript techniques and libraries.</p>
        <p><strong>Form Validation and Data Handling:</strong> Implementing robust form validation and secure data handling to manage user inputs, particularly for the mailing list and review submission forms, posed additional challenges. Ensuring data integrity and security was paramount.</p>
        <h3>Non-Technical Challenges:</h3>
        <p><strong>Content Creation:</strong> Curating and creating comprehensive and relevant content for the firm-specific courses was a significant non-technical challenge. This required in-depth research and collaboration with consulting professionals to ensure the material was accurate and valuable.</p>
        <p><strong>User Engagement:</strong> Designing the platform in a way that would engage users and keep them motivated to use the resources and tools provided was another challenge. This involved creating a user-friendly interface, intuitive navigation, and incorporating interactive elements to enhance user engagement.</p>
        <p><strong>Feedback Incorporation:</strong> Continuously gathering user feedback and incorporating it into the platform to improve functionality and content required a systematic approach. Balancing user suggestions with technical feasibility and overall platform goals was an ongoing task.</p>
        <p>Overcoming these challenges involved a combination of technical expertise, creative problem-solving, and user-centric design. The result is a robust and dynamic platform that effectively prepares users for consulting interviews.</p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>The <strong>Strategy Consulting Prep</strong> platform stands as a comprehensive and innovative solution for aspiring consultants preparing for interviews with top firms like BCG, McKinsey, and Bain & Company. Through a combination of AI-driven simulations, firm-specific courses, and interactive practice sessions, the platform offers a unique and effective preparation experience.</p>
        <p>By leveraging modern web technologies such as HTML, CSS, and JavaScript, along with external libraries for enhanced functionality, the platform ensures a responsive, user-friendly, and engaging experience across all devices. The challenges encountered during the development process, from integrating advanced AI capabilities to ensuring responsive design, were met with creative and technical solutions that have resulted in a robust and dynamic application.</p>
        <p>Moving forward, the focus will be on continually improving the platform based on user feedback and emerging trends in the consulting industry. This includes enhancing the AI chatbot's capabilities, expanding the course offerings, and incorporating new features that further personalize and enrich the user experience.</p>
        <p>In summary, <strong>Strategy Consulting Prep</strong> not only equips users with the tools and knowledge needed for successful consulting interviews but also fosters confidence and proficiency through its innovative approach and comprehensive resources. The ongoing commitment to excellence and user satisfaction ensures that the platform will remain a valuable asset for aspiring consultants for years to come.</p>
      </>
    )
  }
];

const StrategyConsultingPrep = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default StrategyConsultingPrep;
