import React, { createContext, useState } from 'react';

export const BuildingContext = createContext();

const BuildingProvider = ({ children }) => {
  const [building, setBuilding] = useState(null);

  return (
    <BuildingContext.Provider value={{ building, setBuilding }}>
      {children}
    </BuildingContext.Provider>
  );
};

export default BuildingProvider;
