import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/Screenshot 2024-07-11 142646.png';
import visualization2 from '../images/Screenshot 2024-07-11 142814.png';


const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <p>
          This project encapsulates my exploration of Augmented Reality (AR) and computer vision through three distinct applications using OpenCV and MediaPipe. These applications demonstrate various practical uses of AR and computer vision, showcasing the potential of these technologies in different interactive contexts.
        </p>
        <p>
          The first application is a drawing tool controlled by hand gestures. It uses MediaPipe to detect hand landmarks and interpret gestures such as pinching to draw on the screen and displaying a peace sign to change the drawing color. The system tracks hand movements in real-time, allowing for an intuitive and dynamic drawing experience directly on a video feed.
        </p>
        <p>
          The second application involves controlling the system volume using hand gestures. By detecting the distance between the thumb and index finger, the application maps this distance to volume levels, providing a touchless interface for volume control. This application highlights how AR and computer vision can be used to create natural and seamless user interfaces for everyday tasks.
        </p>
        <p>
          The third application is a simple game that tracks the movements of two players using the MediaPipe Holistic model. It monitors the positions of the players' arms and awards points based on their movements, creating an engaging and interactive game experience. This demonstrates the use of pose estimation for interactive gaming and fitness applications.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="Quora Upvotes" style={{ width: '40%', height: 'auto' }} />
            <img src={visualization2} alt="Quora Upvotes" style={{ width: '40%', height: 'auto' }} />
          </div>
        <p>
          Overall, these projects illustrate the versatility and power of AR and computer vision technologies in creating interactive and user-friendly applications. By leveraging real-time video processing and machine learning models, these applications provide a glimpse into the future of human-computer interaction.
        </p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>
          These AR and computer vision projects showcase a variety of features designed to demonstrate the practical applications and potential of these technologies:
        </p>
        <p>
          <strong>Hand Gesture Drawing Tool:</strong> Utilizes MediaPipe to detect and track hand landmarks in real-time, allowing for fluid and responsive interactions. Recognizes specific hand gestures such as pinching to draw and the peace sign to change colors, providing an intuitive and interactive drawing experience. Maintains drawings on an overlay that stays on screen, enabling continuous and smooth drawing without interruptions. Changes drawing color based on detected gestures, adding an element of creativity and customization.
        </p>
        <p>
          <strong>Volume Control with Hand Gestures:</strong> Uses MediaPipe to track hand landmarks in real-time, ensuring precise and responsive control. Maps the distance between the thumb and index finger to volume levels, allowing for seamless, touchless volume adjustment. Directly controls the system volume using the pycaw library, demonstrating practical integration with existing system functionalities.
        </p>
        <p>
          <strong>Interactive Game with Pose Estimation:</strong> Employs MediaPipe Holistic to track the full body, including pose landmarks, enabling comprehensive movement tracking. Tracks players' arm movements and awards points based on their actions, creating a competitive and engaging game environment. Differentiates between two players based on their position in the frame, allowing for multiplayer interaction. Provides real-time visual feedback by drawing pose landmarks and connections on the video feed, enhancing the interactive experience.
        </p>
        <p>
          These features highlight the capabilities of AR and computer vision in creating interactive, user-friendly applications that can be used in various contexts, from creative tools to practical system controls and engaging games.
        </p>
      </>
    )
  },
  {
    id: 'technology-used',
    title: 'Technology Used',
    content: (
      <>
        <p>
          These AR and computer vision projects leverage a range of technologies to achieve their functionality:
        </p>
        <p>
          <strong>OpenCV:</strong> A powerful library for real-time computer vision, used for capturing and processing video feeds, as well as drawing on images.
        </p>
        <p>
          <strong>MediaPipe:</strong> A framework developed by Google for building multimodal applied machine learning pipelines. It is used for hand tracking and pose estimation, providing real-time detection and tracking of landmarks.
        </p>
        <p>
          <strong>NumPy:</strong> A fundamental package for scientific computing with Python, used here for numerical operations, such as calculating distances between landmarks.
        </p>
        <p>
          <strong>PyCaw:</strong> A library that provides programmatic control of the Windows audio system, used in the volume control application to adjust system volume based on hand gestures.
        </p>
        <p>
          <strong>Comtypes:</strong> A library for interfacing with Windows COM objects, used in conjunction with PyCaw for volume control.
        </p>
        <p>
          <strong>Math:</strong> Python's standard library for mathematical functions, used for distance calculations and other numerical operations.
        </p>
        <p>
          <strong>Webcam:</strong> The primary hardware used for capturing live video feeds, essential for real-time processing and interaction.
        </p>
        <p>
          These technologies work together to provide robust and responsive AR and computer vision applications, enabling real-time interaction and enhancing user experience. By combining these tools, the projects demonstrate the versatility and power of modern computer vision and machine learning techniques.
        </p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>
          Developing these AR and computer vision projects presented several challenges that provided valuable learning experiences:
        </p>
        <p>
          <strong>Hand Gesture Drawing Tool:</strong> Achieving accurate and real-time hand tracking was a primary challenge. Ensuring the MediaPipe hand model could reliably detect and track hand landmarks under various lighting conditions and hand positions required fine-tuning and extensive testing. Differentiating between gestures such as pinching for drawing and making a peace sign for color changes required precise gesture recognition and handling potential overlaps or misinterpretations.
        </p>
        <p>
          <strong>Volume Control with Hand Gestures:</strong> Implementing a smooth and responsive volume control system posed challenges in mapping the distance between the thumb and index finger to volume levels. The system needed to handle rapid changes in hand positions and ensure that volume adjustments were intuitive and precise. Integrating the pycaw library for system volume control also involved addressing compatibility issues and ensuring stable interaction with the Windows audio system.
        </p>
        <p>
          <strong>Interactive Game with Pose Estimation:</strong> Creating an engaging and accurate scoring system for the interactive game required robust full-body pose tracking using the MediaPipe Holistic model. Differentiating between the two players based on their positions in the frame and accurately tracking their movements for scoring were challenging tasks. Ensuring real-time performance while processing the video feed and updating scores demanded efficient coding practices and optimization techniques.
        </p>
        <p>
          Across all three projects, maintaining real-time performance was a consistent challenge. Processing video feeds, performing complex calculations, and updating visual elements in real-time required efficient use of resources and careful management of computational overhead. Ensuring compatibility across different hardware and software environments added another layer of complexity.
        </p>
        <p>
          These challenges not only enhanced my understanding of AR and computer vision technologies but also improved my problem-solving skills and ability to develop robust, real-time interactive applications.
        </p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>
          In conclusion, these AR and computer vision projects illustrate the potential of combining real-time video processing, machine learning, and interactive technologies to create innovative applications. From a drawing tool controlled by hand gestures to a touchless volume control system and an interactive game with pose estimation, these projects showcase the versatility and power of AR and computer vision.
        </p>
        <p>
          The development process involved overcoming significant challenges, including accurate hand and pose tracking, real-time performance optimization, and seamless integration with system functionalities. These experiences provided valuable insights into the practical applications of computer vision and AR technologies, highlighting their potential in various domains such as creative tools, user interface design, and interactive entertainment.
        </p>
        <p>
          Moving forward, these projects can be further enhanced by improving gesture recognition accuracy, expanding the range of detectable gestures, and integrating more advanced machine learning models for better performance. Additionally, exploring other applications of AR and computer vision, such as augmented reality experiences and advanced human-computer interaction interfaces, can open up new possibilities for innovation.
        </p>
        <p>
          Overall, these projects demonstrate how AR and computer vision can transform everyday interactions and create engaging, intuitive user experiences, paving the way for future advancements in this exciting field.
        </p>
      </>
    )
  }
];

const ARAndComputerVisionExploration = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default ARAndComputerVisionExploration;





