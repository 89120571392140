import React, { useContext } from 'react';
import ProjectLayout from './ProjectLayout';
import { XPContext } from './XPProvider'; // Import XPContext


const sections = [
  {
    id: 'summary',
    title: 'Summary',
    content: (
      <>
        <h1>Project 6</h1>
        <p>Summary of project 6</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Curabitur et libero nec mauris tincidunt auctor.</p>
      </>
    )
  },
  {
    id: 'goals',
    title: 'Goals',
    content: (
      <>
        <h2>Goals</h2>
        <p>Detail the goals of the project.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Curabitur et libero nec mauris tincidunt auctor.</p>
      </>
    )
  },
  {
    id: 'implementation',
    title: 'Implementation',
    content: (
      <>
        <h2>Implementation</h2>
        <p>Describe the implementation process.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Curabitur et libero nec mauris tincidunt auctor.</p>
      </>
    )
  },
  {
    id: 'evaluation',
    title: 'Evaluation',
    content: (
      <>
        <h2>Evaluation</h2>
        <p>Evaluate the outcomes and process.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Curabitur et libero nec mauris tincidunt auctor.</p>
      </>
    )
  },
  {
    id: 'nextSteps',
    title: 'Next Steps',
    content: (
      <>
        <h2>Next Steps</h2>
        <p>Outline the next steps or future directions.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Curabitur et libero nec mauris tincidunt auctor.</p>
      </>
    )
  }
];

const Project6 = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default Project6;