import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { BuildingContext } from './BuildingContext';
import './lobby.css';

const LobbyLayout = ({ onUnlockMovement }) => {
  const navigate = useNavigate();
  const { building, setBuilding } = useContext(BuildingContext);
  const [showModal, setShowModal] = useState(false);
  const [currentFloor, setCurrentFloor] = useState(1);
  const [isTalking, setIsTalking] = useState(false);

  useEffect(() => {
    console.log('Lobby received building data:', building);

    const floors = [1, 2, 3, 2];
    let index = 0;
    const interval = setInterval(() => {
      setCurrentFloor(floors[index]);
      index = (index + 1) % floors.length;
    }, 2000); // Change floor every 2 seconds
    return () => clearInterval(interval);
  }, [building]);

  const handleElevatorClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateToProject = (path) => {
    setShowModal(false);
    navigate(path);
  };

  const handleExit = () => {
    const exitPosition = building?.exit || { x: 0, z: 0 };
    console.log('Exiting to position:', exitPosition);
    onUnlockMovement();
    navigate('/', { state: { resetPosition: true, exit: exitPosition } });
  };

  const handleReceptionistClick = () => {
    setIsTalking(true);
    setTimeout(() => {
      setIsTalking(false);
    }, 3000);
  };

  return (
    <div className="lobby-container">
      <div className="lobby-background">
        <div className="elevator" onClick={handleElevatorClick}>
          <div className="floor-indicator">{currentFloor}</div>
          <div className="elevator-doors">
            <div className="door-left"></div>
            <div className="door-right"></div>
          </div>
          <div className="call-button"></div>
        </div>
        <div className="receptionist" onClick={handleReceptionistClick}>
          <div className="reception-desk">
            <div className={`receptionist-person ${isTalking ? 'talking' : ''}`}>
              <div className="receptionist-head">
                <div className="receptionist-hair"></div>
                <div className="receptionist-eyes"></div>
                <div className="receptionist-mouth"></div>
              </div>
              <div className="receptionist-body"></div>
            </div>
          </div>
          <div className={`receptionist-message ${isTalking ? 'show' : ''}`}>
            {building?.receptionistMessage}
          </div>
        </div>
        <div className="exit-button" onClick={handleExit}>
          <div className="door-container">
            <div className="door-background"></div>
            <div className="door"></div>
            <div className="door-text">Leave Building</div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>Select a Project</h2>
            <div className="elevator-menu">
              {building?.projects && building.projects.length > 0 ? (
                building.projects.map((project, index) => (
                  <div key={index} className="elevator-button" onClick={() => navigateToProject(project.path)}>
                    <div className="button"><span>{index + 1}</span></div>
                    <a href="#" className="project-link">{project.name}</a>
                  </div>
                ))
              ) : (
                <p>No projects available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LobbyLayout;
