import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import workloadImage from '../images/workload.png'; // Import the image
import { XPContext } from '../XPProvider'; // Import XPContext

const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <p>The "Atlas Major Project" is an innovative data collection and analysis tool designed to automate the gathering and evaluation of course and job data for various university majors. The project utilizes Python along with libraries such as Selenium, BeautifulSoup, and SQLite to extract, store, and analyze data from university websites and external job databases. The project begins by web scraping university websites to collect comprehensive course information, including course titles, median grades, workload, student interest, and other relevant metrics. This data is then organized and stored in a local SQLite database. In addition to academic data, the project fetches job titles and salary information related to each major using external APIs. This information is also stored in the database, facilitating a holistic view of educational and career outcomes for each major. This project is particularly useful for students, academic advisors, and educational institutions, providing data-driven insights to guide course selection and career planning. By automating the data collection process, the "Atlas Major Project" significantly reduces manual effort and enhances decision-making through detailed analysis and visualizations.</p>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>The "Atlas Major Project" incorporates a robust set of features aimed at providing comprehensive insights into university courses and related job opportunities. The project automates course data collection through web scraping, gathering detailed information on course titles, median grades, workload, student understanding, desire, expectation, and interest. This ensures that the data is both extensive and current, eliminating the need for manual updates.</p>
        <p>To complement the academic data, the project fetches job titles and salary information relevant to each major by leveraging external APIs. This functionality links educational outcomes with potential career paths, offering users a clear view of the job market related to their major. The collected data is meticulously stored in a structured SQLite database, which is organized into tables for courses, majors, job titles, and salaries. This setup not only ensures efficient data management but also facilitates easy data retrieval and analysis.</p>
        <p>A significant feature of the project is the grade conversion system, which standardizes letter grades to a 4.0 scale. This consistency is crucial for accurate data analysis and comparison across different courses and majors. Furthermore, the system calculates average values for various metrics, such as workload and salary, for each major. These averages provide valuable insights into the typical demands and financial prospects of different fields of study, helping users make informed decisions.</p>
        <p>Visualization tools, powered by Matplotlib, are a key component of the project. They generate graphical representations of average metrics per major, making data interpretation straightforward and intuitive. Additionally, the project plots stock price histories for companies related to job listings, offering users financial insights into potential employers and aiding in career planning.</p>
        <img src={workloadImage} alt="Graph showing mean workload per major" style={{ width: '50%', height: 'auto', display: 'block', margin: '20px auto' }} />
        <p>The project also features automated scheduling, cycling through a predefined list of majors to periodically update the data. This ensures that the information remains relevant and up-to-date, while managing API call limits and maintaining system performance by restricting the number of records processed per cycle.</p>
        <p>Error handling and robustness are integral to the project's design. The system includes mechanisms to manage network issues, API changes, and data inconsistencies, ensuring reliable and seamless operation under various conditions. This robustness extends to the project's financial analysis capabilities, where it retrieves and analyzes stock prices for companies associated with job listings. Historical price trends help users evaluate the financial health and stability of potential employers.</p>
        <p>Finally, the project is designed with extensibility in mind. Its architecture allows for the integration of additional data sources and new features, ensuring that it can evolve to meet changing needs and requirements. This flexibility makes the "Atlas Major Project" a powerful and adaptable tool for educational and career planning, capable of growing alongside user needs and technological advancements.</p>
      </>
    )
  },
  {
    id: 'technology',
    title: 'Technology Used',
    content: (
      <>
        <p>The "Atlas Major Project" leverages a variety of technologies to achieve its comprehensive data collection, storage, and analysis capabilities.</p>
        <p><strong>Programming Languages</strong>: The core functionality is built using Python, chosen for its simplicity and extensive library support.</p>
        <p><strong>Web Scraping and Automation</strong>: Selenium and BeautifulSoup are utilized to automate the extraction of data from university websites. Selenium handles the browser automation, navigating web pages and triggering dynamic content, while BeautifulSoup parses the HTML to extract the relevant data.</p>
        <p><strong>Database Management</strong>: SQLite is used as the database management system. It offers a lightweight and efficient way to store and manage the collected data. The database is structured with tables for courses, majors, job titles, and salaries, ensuring organized data storage and easy retrieval.</p>
        <p><strong>APIs and Data Retrieval</strong>: The project integrates several APIs to enrich its dataset:</p>
        <ul>
          <li>OpenAI API: Generates job titles related to each major.</li>
          <li>Jooble API: Retrieves job listings and salary information.</li>
          <li>Polygon.io and Alpha Vantage API: Fetch stock price data for companies associated with job listings.</li>
        </ul>
        <p><strong>Data Analysis and Visualization</strong>: Pandas is used for data manipulation and analysis, providing powerful tools for cleaning and aggregating data. Matplotlib generates visualizations, including bar charts and line plots, to represent average metrics per major and stock price histories.</p>
        <p><strong>Error Handling and Robustness</strong>: The project includes error handling mechanisms to manage network issues, API changes, and data inconsistencies. This ensures the system remains reliable and functional under various conditions.</p>
        <p><strong>Automation and Scheduling</strong>: Python's built-in libraries handle the automation and scheduling of data updates, ensuring the system periodically refreshes its data to remain current.</p>
        <p><strong>Extensibility</strong>: The project's architecture is designed to be easily extensible. New data sources and features can be integrated with minimal modifications, allowing the system to evolve and adapt to new requirements and technologies.</p>
        <p>These technologies work together seamlessly to provide a robust, automated, and extensible system for educational and career planning.</p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>Developing the "Atlas Major Project" involved navigating several technical and operational challenges.</p>
        <p><strong>Web Scraping Complexity</strong>: Handling the dynamic content of university websites posed a significant challenge. Many modern websites use JavaScript to load data asynchronously, making it difficult to scrape content directly from the HTML source. Selenium was employed to automate browser interactions, ensuring all dynamic content was fully loaded before scraping. This required careful handling of web elements and synchronization to ensure reliable data extraction.</p>
        <p><strong>API Rate Limits</strong>: The project relies heavily on external APIs to fetch job titles, salary information, and stock prices. Each API has its own rate limits and usage policies, necessitating strategies to manage these constraints. The project limits the number of records processed per cycle and uses scheduling to spread out API requests over time, avoiding hitting rate limits and ensuring smooth data retrieval.</p>
        <p><strong>Data Cleaning and Processing</strong>: Converting and normalizing data from various sources was a significant challenge. Different grading scales, job title formats, and salary representations required consistent processing for accurate analysis. This included converting letter grades to a 4.0 scale, standardizing job titles, and normalizing salary data to annual figures. Handling these variations required robust data processing pipelines and thorough validation to maintain data integrity.</p>
        <p><strong>Error Handling</strong>: Ensuring the system's robustness in the face of network issues, API changes, and data inconsistencies was crucial. The project incorporated extensive error handling mechanisms to manage these scenarios. Retry logic was implemented for network requests, fallbacks were provided for missing data, and comprehensive logging was used to track and diagnose issues, maintaining system reliability and continuous operation.</p>
        <p><strong>Visualization</strong>: Creating meaningful and interpretable visualizations from the collected data required careful design and implementation. The challenge was to present complex data in a simple and intuitive manner. Using Matplotlib, the project generated bar charts and line plots that clearly depicted average metrics per major and stock price trends. Ensuring these visualizations were both informative and easy to understand involved iterative design and user feedback.</p>
        <p><strong>Extensibility and Maintenance</strong>: Designing the system to be easily extensible and maintainable was another significant challenge. The architecture needed to accommodate new data sources and features without extensive modifications. This was achieved by modularizing the code and using clear interfaces between different components. Comprehensive documentation and testing were essential to ensure the system could be easily maintained and updated as needed.</p>
        <p>These challenges required innovative solutions and careful planning to overcome, ensuring the "Atlas Major Project" is robust, reliable, and scalable. The lessons learned from addressing these challenges have significantly contributed to the project's overall success and usability.</p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>The "Atlas Major Project" successfully automates the collection, storage, and analysis of data related to university courses and associated job opportunities. By leveraging web scraping, APIs, and advanced data processing techniques, the project provides users with comprehensive insights into academic and career planning.</p>
        <p>Through the use of Python, Selenium, BeautifulSoup, SQLite, and various APIs, the project ensures that data is current, accurate, and easily accessible. Visualization tools like Matplotlib make interpreting this data straightforward, providing clear and intuitive graphical representations. The project's robust error handling and extensibility further enhance its reliability and adaptability, making it a valuable tool for students, academic advisors, and educational institutions.</p>
        <p>The next steps for the "Atlas Major Project" involve expanding its data sources and features. Incorporating additional university websites, enhancing the granularity of job market data, and integrating more sophisticated machine learning models for predictive analytics could further enrich the insights provided. Moreover, improving the user interface and making the system more interactive would enhance user experience and accessibility.</p>
        <p>In summary, the "Atlas Major Project" stands as a powerful example of how automation and data science can be harnessed to support educational and career decision-making. Its continued development promises even greater utility and impact in the future.</p>
      </>
    )
  }
];

const AtlasMajorProject = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default AtlasMajorProject;





