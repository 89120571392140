import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import LobbyLayout from './LobbyLayout';
import { BuildingContext } from './BuildingContext';

const Lobby = ({ onUnlockMovement }) => {
  const location = useLocation();
  const { building, setBuilding } = useContext(BuildingContext);

  useEffect(() => {
    if (location.state?.building) {
      setBuilding(location.state.building);
      console.log('Lobby received building data:', location.state.building);
    } else {
      console.log('Lobby received building data: {}');
    }
    onUnlockMovement();
  }, [location.state, setBuilding, onUnlockMovement]);

  return (
    <LobbyLayout 
      onUnlockMovement={onUnlockMovement}
    />
  );
};

export default Lobby;
