import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export const setupControls = (camera, renderer, setShowInstructions) => {
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enablePan = true;
  controls.enableRotate = true;
  controls.enableZoom = true;

  controls.addEventListener('start', () => setShowInstructions(false));

  return controls;
};
