import * as THREE from 'three';

export const handleMovement = (event, keysPressed, disableMovement) => {
  if (!disableMovement) {
    keysPressed[event.key] = event.type === 'keydown';
  }
};

export const checkCollisions = (person, buildings, navigate, keysPressed, setDisableMovement, setBuilding) => {
  const personBox = new THREE.Box3().setFromObject(person);

  for (const building of buildings) {
    if (building.userData && building.userData.entryRegion) {
      const entryRegionBox = new THREE.Box3().setFromObject(building.userData.entryRegion);

      if (personBox.intersectsBox(entryRegionBox)) {
        const entryCenter = new THREE.Vector3();
        entryRegionBox.getCenter(entryCenter);

        const distance = person.position.distanceTo(entryCenter);

        if (distance < 1) {
          if (building.userData.path) {
            const serializableBuilding = {
              info: building.userData.info,
              path: building.userData.path,
              exit: building.userData.exit || { x: 0, z: 0 },
              receptionistMessage: building.userData.receptionistMessage,
              projects: building.userData.projects
            };

            console.log('Navigating to lobby with building data:', serializableBuilding);
            setBuilding(serializableBuilding); // Set building data in context

            if (!keysPressed.navigationTriggered) {
              keysPressed.navigationTriggered = true;
              setDisableMovement(true); // Disable movement

              // Clear the keysPressed state
              Object.keys(keysPressed).forEach(key => {
                keysPressed[key] = false;
              });

              // Move character to exit point
              person.position.set(serializableBuilding.exit.x, 0.5, serializableBuilding.exit.z);

              navigate('/lobby', { state: { resetPosition: true, building: serializableBuilding } });
              break;
            }
          } else {
            console.error('Path is undefined for the building:', building);
          }
        }
      }
    }

    // Check collisions with invisible walls
    const walls = building.children.filter(child => child.material?.opacity === 0);
    for (const wall of walls) {
      const wallBox = new THREE.Box3().setFromObject(wall);
      if (personBox.intersectsBox(wallBox)) {
        // Stop the person from moving through the wall
        // Reverse the last movement
        if (keysPressed.ArrowRight) {
          person.position.x -= 0.15;
        }
        if (keysPressed.ArrowLeft) {
          person.position.x += 0.15;
        }
        if (keysPressed.ArrowUp) {
          person.position.z -= 0.15;
        }
        if (keysPressed.ArrowDown) {
          person.position.z += 0.15;
        }
      }
    }
  }

  // Reset the navigationTriggered flag if not near any entry region
  const isNearEntry = buildings.some(building => {
    if (building.userData && building.userData.entryRegion) {
      const entryRegionBox = new THREE.Box3().setFromObject(building.userData.entryRegion);
      return personBox.intersectsBox(entryRegionBox);
    }
    return false;
  });

  if (!isNearEntry) {
    keysPressed.navigationTriggered = false;
  }
};
