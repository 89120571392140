import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import workloadImage2 from '../images/Screenshot 2024-07-06 142235.png';
import workloadImage1 from '../images/Screenshot 2024-07-06 142202.png';
import { XPContext } from '../XPProvider'; // Import XPContext


const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <p>EcoFlux Insights is an innovative project designed to address the growing issue of excessive energy consumption in households. As part of an assignment to leverage data for real-world impact, the project aims to optimize energy use through advanced data analytics and machine learning. Given the rising utility costs and significant environmental impacts, EcoFlux Insights focuses on providing personalized and actionable recommendations for energy savings. By analyzing detailed patterns and predicting future consumption, the project utilizes granular data from smart meters and comprehensive weather data to gain deep insights into household energy consumption. This enables the delivery of tailored energy optimization services, significantly reducing energy use and promoting sustainable living. Through its data-driven approach, EcoFlux Insights aspires to transform how households manage their energy consumption, ushering in a new era of efficiency and environmental responsibility.</p>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>EcoFlux Insights incorporates a robust set of features to provide comprehensive energy optimization services for households:</p>
        <p><strong>Real-Time Monitoring</strong>: Users can track their energy consumption in real-time through the EcoFlux app, gaining instant visibility into their energy usage patterns. This feature helps users identify areas for improvement and make immediate adjustments to their energy use.</p>
        <p><strong>Predictive Analytics</strong>: By analyzing historical usage patterns and weather data, EcoFlux Insights predicts future energy consumption. This allows users to anticipate their energy needs and identify opportunities for savings, making proactive adjustments to their energy use.</p>
        <p><strong>Automated Energy Reduction</strong>: With user consent, EcoFlux Insights can remotely optimize settings on connected smart devices during peak usage times or when the household is empty. This automation ensures energy savings without compromising comfort, making it easier for users to maintain efficient energy use.</p>
        <p><strong>Personalized Recommendations</strong>: Users receive tailored advice on when to use certain appliances, how to optimize heating and cooling settings, and other tips for reducing energy consumption. These recommendations are based on the detailed analysis of their unique energy usage patterns and external factors like weather conditions.</p>
      </>
    )
  },
  {
    id: 'technology',
    title: 'Technology Used',
    content: (
      <>
        <p>EcoFlux Insights leverages a variety of advanced technologies to provide comprehensive energy optimization services:</p>
        <p><strong>Data Collection and Sources</strong>:</p>
        <ul>
          <li><strong>Smart Meter Data</strong>: Sourced from Pecan Street Inc.'s Dataport, this data provides granular insights into household energy consumption on an hourly and minute-by-minute basis. The data includes detailed information on the energy usage of individual appliances and systems within the household, crucial for identifying peak usage times and habitual consumption patterns.</li>
          <li><strong>Weather Data</strong>: Sourced from NOAA Climate Data Online, this dataset includes a comprehensive array of weather-related information such as temperature, precipitation, snowfall, and other meteorological variables. This data is essential for understanding the external conditions that influence energy consumption patterns.</li>
        </ul>
        <p><strong>Data Processing and Analysis</strong>:</p>
        <ul>
          <li><strong>Python</strong>: The core programming language used for data manipulation and analysis, chosen for its versatility and extensive library support.</li>
          <li><strong>Pandas</strong>: Utilized for data manipulation, including merging datasets, filtering data, and performing exploratory data analysis.</li>
          <li><strong>Matplotlib and Seaborn</strong>: Used for data visualization, helping to create plots and graphs that illustrate energy consumption patterns and correlations with weather conditions.</li>
          <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={workloadImage1} alt="Graph showing average temperature and energy consumption" style={{ width: '45%', height: 'auto' }} />
            <img src={workloadImage2} alt="Graph showing correlation of weather variables with grid energy consumption" style={{ width: '45%', height: 'auto' }} />
          </div>
        </ul>
        <p><strong>Machine Learning and Predictive Analytics</strong>:</p>
        <ul>
          <li><strong>Time Series Analysis</strong>: Employed to understand energy consumption patterns over time and to predict future usage.</li>
          <li><strong>Machine Learning Regression Models</strong>: Linear Regression, Random Forest, and Gradient Boosting models are used to predict energy consumption based on factors like time of day, weather conditions, and specific appliance usage.</li>
          <li><strong>Clustering and Anomaly Detection (Future Work)</strong>: Plans to implement clustering techniques to categorize households based on energy usage patterns and anomaly detection to alert users about unusual spikes in consumption, potentially indicating faulty appliances.</li>
        </ul>
        <p><strong>Data Storage</strong>:</p>
        <ul>
          <li><strong>SQLite</strong>: A lightweight database management system used to store the processed data, ensuring organized and efficient data management.</li>
        </ul>
        <p>By integrating these technologies, EcoFlux Insights is able to deliver detailed, actionable insights into household energy consumption, enabling users to optimize their energy use and reduce their environmental footprint.</p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>Developing EcoFlux Insights involved navigating several technical and operational challenges:</p>
        <p><strong>Web Scraping and Data Integration</strong>: One of the primary challenges was integrating diverse data sources, such as smart meter data and weather data. This involved extracting granular energy consumption data from Pecan Street Inc.'s Dataport and weather data from NOAA Climate Data Online. Merging these datasets required handling different data formats, ensuring temporal alignment, and dealing with missing values. Achieving a seamless integration was crucial for accurate analysis and prediction.</p>
        <p><strong>Handling Large Datasets</strong>: The project involved processing substantial amounts of data. For instance, the weather data alone comprised over 136,000 rows and 20 columns, while the energy consumption dataset contained over 805,000 rows and 79 columns. Efficient data handling and processing techniques were essential to manage memory usage and computational load. This required optimizing data processing workflows and leveraging powerful Python libraries like Pandas for efficient data manipulation.</p>
        <p><strong>Machine Learning Model Development</strong>: Building predictive models to forecast energy consumption posed significant challenges. Selecting the appropriate machine learning algorithms, such as Linear Regression, Random Forest, and Gradient Boosting, was critical. Each model had to be trained and validated using historical data, which involved splitting the data into training and testing sets, imputing missing values, and tuning hyperparameters. Ensuring model accuracy and generalizability was a complex task, particularly given the variability in energy consumption patterns.</p>
        <p><strong>Visualization and Interpretation of Results</strong>: Creating meaningful visualizations to represent complex data patterns and correlations was another challenge. Using Matplotlib and Seaborn, the project aimed to produce clear and intuitive plots that could convey insights effectively. Ensuring that these visualizations were both informative and easy to interpret required careful design and iterative refinement based on user feedback.</p>
        <p><strong>Automation and Scalability</strong>: Automating the data collection, processing, and analysis workflow was essential for scalability. The system needed to handle periodic updates and integrate new data seamlessly. Designing a robust and scalable architecture to support these automated processes was a significant challenge, requiring thorough planning and testing to ensure reliability and efficiency.</p>
        <p><strong>Error Handling and Robustness</strong>: Ensuring the robustness of the system in the face of network issues, data inconsistencies, and other operational challenges was critical. Implementing comprehensive error handling mechanisms, such as retry logic for network requests and fallback strategies for missing data, was essential to maintain system reliability and provide a seamless user experience.</p>
        <p>Overcoming these challenges required a combination of technical expertise, innovative problem-solving, and meticulous planning, ultimately contributing to the robustness and effectiveness of EcoFlux Insights.</p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>EcoFlux Insights has successfully developed a comprehensive, data-driven solution to address the pressing issue of excessive household energy consumption. By leveraging advanced analytics and machine learning, the project provides users with real-time monitoring, predictive analytics, automated energy reduction, and personalized recommendations. These features enable households to optimize their energy use, reduce utility costs, and minimize their environmental impact.</p>
        <p>The project utilized a range of technologies, including Python, Pandas, Matplotlib, Seaborn, and SQLite, along with data from smart meters and weather sources. This robust technological foundation ensured efficient data processing, accurate predictive modeling, and insightful visualizations.</p>
        <p>Despite the challenges of integrating diverse datasets, handling large volumes of data, developing and validating machine learning models, and ensuring system robustness, EcoFlux Insights has demonstrated significant potential in transforming how households manage their energy consumption. The insights gained from this project can drive further innovation and improvement in the field of energy optimization.</p>
        <p>Looking forward, EcoFlux Insights aims to expand its capabilities by incorporating clustering techniques and anomaly detection to provide even more tailored and proactive energy-saving solutions. Enhancing the user interface and making the system more interactive will further improve user experience and accessibility. As EcoFlux Insights continues to evolve, it promises to make a substantial impact on promoting sustainable living and energy efficiency.</p>
      </>
    )
  }
];

const EcoFlux = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default EcoFlux;
