import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/600.gif';



const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <p>
          This project is an automated reservation system I developed to book tables at restaurants on OpenTable using Selenium WebDriver with Python. The primary purpose of this project is to secure hard-to-get reservations at popular restaurants that often fill up quickly. The system navigates the OpenTable website, selects a restaurant, and makes a reservation for a specified party size and time.
        </p>
        <p>
          The idea for this project came from my personal experience trying to get a reservation at a highly sought-after restaurant. After numerous failed attempts, I decided to automate the process to save time and increase my chances of success. The script automates the repetitive task of checking for available reservations, selecting the desired options, and confirming the booking.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="OpenTable Reservation Flow" style={{ width: '45%', height: 'auto' }} />
          </div>
        <p>
          One notable success story from using this system is how it helped me secure an internship. By automating the reservation process, I managed to get a reservation for a board member at a prestigious restaurant. This not only impressed the board member but also showcased my technical skills and resourcefulness, leading to my first internship offer.
        </p>
        <p>
          Overall, this project combines my passion for technology with practical problem-solving, demonstrating the potential of automation to simplify everyday tasks and create unique opportunities.
        </p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>
          The automated reservation system streamlines the entire process of booking a table, from navigating the OpenTable website to confirming the booking, ensuring a seamless user experience. By running the browser in incognito mode, the script avoids cached data, guaranteeing a fresh browsing session every time it operates. This is particularly useful for high-demand reservations where every second counts.
        </p>
        <p>
          One of the standout features is the dynamic element handling, which employs multiple locators such as XPATH, ID, and NAME to find elements on the webpage. This increases the reliability and robustness of the script, ensuring that it can handle various website structures and updates. In addition, the script incorporates error handling and retry mechanisms, continuously attempting to select elements until successful, which is critical for overcoming occasional website glitches.
        </p>
        <p>
          The system offers flexibility in party size and time selection, allowing users to specify their preferred reservation details. It also adeptly navigates date pickers to select specific dates for reservations. Throughout the process, the script provides user feedback via console output, helping with debugging and tracking the progress of reservation attempts. Furthermore, its scalable design means it can be adapted to automate reservations on other booking platforms with minimal modifications, showcasing its versatility and potential for broader application.
        </p>
      </>
    )
  },
  {
    id: 'technology-used',
    title: 'Technology Used',
    content: (
      <>
        <p>
          This project leverages a variety of technologies to achieve its goals:
        </p>
        <p>
          <strong>Python</strong>: The core programming language used for scripting, chosen for its simplicity and powerful libraries.
        </p>
        <p>
          <strong>Selenium WebDriver</strong>: A tool for automating web browser interactions. It enables the script to control the browser and perform tasks such as clicking buttons, filling out forms, and selecting options from dropdown menus.
        </p>
        <p>
          <strong>ChromeDriver</strong>: A standalone server that implements the WebDriver's wire protocol for Chromium. This allows the script to control the Chrome browser.
        </p>
        <p>
          <strong>HTML and CSS Selectors</strong>: Fundamental web technologies used to identify and interact with elements on the webpage.
        </p>
        <p>
          <strong>XPath</strong>: A powerful language for navigating through elements and attributes in an XML document, used here to locate elements on the webpage.
        </p>
        <p>
          <strong>Selenium WebDriver Common Libraries</strong>: Including By, Keys, and Select, these libraries facilitate interactions with web elements, simulating user actions like typing, clicking, and selecting from dropdowns.
        </p>
        <p>
          These technologies work together to create a robust and flexible system for automating the reservation process on OpenTable.
        </p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>
          Developing this automated reservation system came with its set of challenges. One of the primary hurdles was dealing with the dynamic nature of web elements on the OpenTable site. Elements frequently changed their attributes, which required implementing multiple locators (like XPATH, ID, and NAME) to ensure consistent element identification. This was crucial for maintaining the script's reliability, especially when faced with website updates or changes in the page layout.
        </p>
        <p>
          Another challenge was handling the timing issues associated with web automation. Elements on the webpage often took varying amounts of time to load, necessitating the use of sleep intervals and retry mechanisms. This ensured that the script could wait for elements to become interactable, reducing the risk of errors due to elements not being ready for interaction.
        </p>
        <p>
          Error handling was another significant aspect. The script had to manage exceptions gracefully, retrying actions until successful. This involved extensive testing and debugging to identify and address potential failure points. For instance, selecting the party size and reservation time required several attempts with different locators and methods to ensure that the script could handle various scenarios and fallbacks effectively.
        </p>
        <p>
          Lastly, incorporating incognito mode and avoiding cached data added another layer of complexity. Ensuring that the browser session was fresh each time helped in dealing with session-specific issues but also required careful management of browser options and settings.
        </p>
        <p>
          Overall, these challenges provided valuable learning experiences in web automation, error handling, and creating robust, adaptable scripts capable of performing reliably in a dynamic environment.
        </p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>
          In conclusion, this automated reservation system for OpenTable demonstrates the power and potential of web automation to simplify and streamline everyday tasks. By leveraging Python and Selenium WebDriver, I was able to create a robust solution that effectively navigates the complexities of the OpenTable website to secure hard-to-get reservations.
        </p>
        <p>
          The project was not just a technical achievement but also a personal triumph. It showcased my ability to tackle real-world problems with innovative solutions, directly leading to a valuable internship opportunity. The skills and knowledge gained from this project, including dynamic element handling, error management, and automation techniques, have been instrumental in my development as a programmer.
        </p>
        <p>
          Moving forward, the system has the potential for further enhancements and scalability. It could be adapted to work with other reservation platforms or expanded with features like automated notifications for available slots or integration with calendar applications. These next steps could make the tool even more versatile and user-friendly, providing greater convenience for users.
        </p>
        <p>
          Overall, this project highlights the intersection of technology and practical problem-solving, demonstrating how automation can open doors to new opportunities and efficiencies in our daily lives.
        </p>
      </>
    )
  }
];

const OpenTableReservationBot = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default OpenTableReservationBot;
