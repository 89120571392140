import * as THREE from 'three';

export const addCars = (scene) => {
  const cars = [];
  const carColors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff];
  let previousCarPosition = -15;

  for (let i = 0; i < 5; i++) {
    const carGroup = new THREE.Group();

    const carBodyGeometry = new THREE.BoxGeometry(2, 0.5, 1);
    const carBodyMaterial = new THREE.MeshBasicMaterial({ color: carColors[Math.floor(Math.random() * carColors.length)] });
    const carBody = new THREE.Mesh(carBodyGeometry, carBodyMaterial);
    carBody.position.set(0, 0.25, 0);
    carGroup.add(carBody);

    const windshieldGeometry = new THREE.PlaneGeometry(1, 0.5);
    const windshieldMaterial = new THREE.MeshBasicMaterial({ color: 0x99ccff, side: THREE.DoubleSide, transparent: true, opacity: 0.7 });
    const windshield = new THREE.Mesh(windshieldGeometry, windshieldMaterial);
    windshield.position.set(0.55, 0.4, 0);
    windshield.rotation.y = Math.PI / 2;
    carGroup.add(windshield);

    const wheelGeometry = new THREE.CylinderGeometry(0.2, 0.2, 0.1, 32);
    const wheelMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
    const positions = [
      [-0.75, -0.05, 0.5],
      [0.75, -0.05, 0.5],
      [-0.75, -0.05, -0.5],
      [0.75, -0.05, -0.5],
    ];
    positions.forEach(pos => {
      const wheel = new THREE.Mesh(wheelGeometry, wheelMaterial);
      wheel.position.set(...pos);
      wheel.rotation.x = Math.PI / 2;
      carGroup.add(wheel);
    });

    const headlightGeometry = new THREE.CylinderGeometry(0.07, 0.07, 0.15, 32);
    const headlightMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const headlightPositions = [
      [0.9, 0.15, 0.3],
      [0.9, 0.15, -0.3],
    ];
    headlightPositions.forEach(pos => {
      const headlight = new THREE.Mesh(headlightGeometry, headlightMaterial);
      headlight.position.set(...pos);
      headlight.rotation.y = Math.PI / 2;
      carGroup.add(headlight);

      const beamGeometry = new THREE.ConeGeometry(0.15, 1.2, 32);
      const beamMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0.5 });
      const beam = new THREE.Mesh(beamGeometry, beamMaterial);
      beam.position.set(pos[0] + 0.65, pos[1], pos[2]);
      beam.rotation.set(0, 0, Math.PI / 2);
      carGroup.add(beam);
    });

    const minDistance = 15;
    const randomDistance = Math.random() * 2 + minDistance;
    const carPositionX = previousCarPosition + randomDistance;
    carGroup.position.set(carPositionX, 0.25, 4);
    previousCarPosition = carPositionX;

    scene.add(carGroup);
    cars.push(carGroup);
  }

  return cars;
};

export const animateCars = (cars) => {
  const moveSpeed = 0.2;
  const carColors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff, 0x00ffff];

  cars.forEach((carGroup) => {
    carGroup.position.x += moveSpeed * 0.5;
    if (carGroup.position.x > 15) {
      carGroup.position.x = -15;
      carGroup.children[0].material.color.set(carColors[Math.floor(Math.random() * carColors.length)]);
    }
  });
};
