import * as THREE from 'three';

export const addBuildings = (scene, navigate, setBuilding) => {
  const buildings = [];

  const createTextTexture = (text) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 562;
    canvas.height = 386;

    context.font = 'Bold 80px Arial';
    context.fillStyle = 'white';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    // Split text into lines if necessary
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];
    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = context.measureText(currentLine + ' ' + word).width;
      if (width < canvas.width - 20) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);

    const lineHeight = 75;
    const y = canvas.height / 2 - (lines.length - 1) * lineHeight / 2;
    lines.forEach((line, index) => {
      context.fillText(line, canvas.width / 2, y + index * lineHeight);
    });

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true; // Ensure the texture updates
    return texture;
  };

  const createText = (text, position) => {
    const textTexture = createTextTexture(text);
    const material = new THREE.MeshBasicMaterial({ map: textTexture, transparent: true, side: THREE.DoubleSide });
    
    const textGeometry = new THREE.PlaneGeometry(5, 2.5);
    const textMesh = new THREE.Mesh(textGeometry, material);
    textMesh.position.copy(position);
    textMesh.position.y -= 7; // Adjust the height of the text above the building

    // Add depth to the text
    const textDepthGroup = new THREE.Group();
    for (let i = 0; i < 5; i++) {
      const depthMesh = textMesh.clone();
      depthMesh.position.z = -0.05 * i;
      textDepthGroup.add(depthMesh);
    }

    scene.add(textDepthGroup);

    const animate = () => {
      requestAnimationFrame(animate);
      textDepthGroup.position.y = position.y + 4 + Math.sin(Date.now() * 0.002) * 0.2; // Subtle bobbing effect
    };
    animate();
  };

  const buildingData = [
    {
      x: -11, z: -3.5, info: 'School Project Building', path: '/lobby', isSchool: true, exit: { x: -11, z: 0.5 },
      receptionistMessage: 'Welcome to the school project building! In this building you will find more informations on projects I have done at my home university, the University of Michigan School of Information',
      projects: [
        { name: 'Major dificulty and compensation compiler', path: '/projects/AtlasMajorProject' },
        { name: 'EcoFlux Insights - predictive energy consumption', path: '/projects/EcoFlux' },
        { name: 'Population and weather based prediction of E.coli levels', path: '/projects/EcoliPrediction' }
      ]
    },
    {
      x: -2, z: -3, info: 'Front End Project Building', path: '/lobby', exit: { x: -2, z: 0.5 },
      receptionistMessage: 'Welcome to the Front End project building!\nIn this building you will find more information on all of my front end projects.',
      projects: [
        { name: 'The city-scape itself', path: '/projects/TheCityScapeItself' },
        { name: 'Strategy Consulting Interview Prep', path: '/projects/StrategyConsultingPrep' }
      ]
    },
    {
      x: 4, z: -3, info: 'Web Automation and Scraping Building', path: '/lobby', exit: { x: 4, z: 0.5 },
      receptionistMessage: 'Welcome to the Web Automation building! Here you will find information on all of my projects related to automation of the web.',
      projects: [
        { name: 'OpenTable Reservation Bot', path: '/projects/OpenTableReservationBot' },
        { name: 'Social Media Profile Booster', path: '/projects/ProfileBooster' },
        { name: 'Car Data Compiler', path: '/projects/CarDataCompiler' },
        { name: 'LinkedIn Scraper', path: '/projects/LinkedInScraper' }
      ]
    },
    {
      x: 10, z: -3, info: 'AI/ML and Data Science Building', path: '/lobby', exit: { x: 10, z: 0.5 },
      receptionistMessage: 'Welcome to the AI/ML and Data Science project building! This building contains all projects related to artificial intelligence, matchine learning, and computer vision!',
      projects: [
        { name: 'AI Stock Sentiment Bot', path: '/projects/AIStockSentimentBot' },
        { name: 'AR and Computer Vision Exploration', path: '/projects/ARAndComputerVisionExploration' },
        { name: 'Car Price Prediction and Value Finder', path: '/projects/CarValueFinder' },
        { name: 'AI Smart Glasses', path: '/projects/AISmartGlasses' },
        { name: 'AI Blog Generator', path: '/projects/AIBlogGen' }
      ]
    }
  ];

  const createBuilding = (x, z, info, path, isSchool = false, exit, receptionistMessage, projects) => {
    const buildingGroup = new THREE.Group();
    let door;
    let entryRegion;

    if (isSchool) {
      const schoolGeometry = new THREE.BoxGeometry(8, 6, 4);
      const schoolMaterial = new THREE.MeshBasicMaterial({ color: 0x8B0000 });
      const schoolBuilding = new THREE.Mesh(schoolGeometry, schoolMaterial);
      schoolBuilding.position.set(0, 3, 0);
      buildingGroup.add(schoolBuilding);

      const roofGeometry = new THREE.ConeGeometry(6, 3, 4);
      const roofMaterial = new THREE.MeshBasicMaterial({ color: 0xA52A2A });
      const roof = new THREE.Mesh(roofGeometry, roofMaterial);
      roof.position.set(0, 6, 0);
      roof.rotation.y = Math.PI / 4;
      buildingGroup.add(roof);

      const columnGeometry = new THREE.CylinderGeometry(0.3, 0.3, 4, 32);
      const columnMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF });
      for (let i = -3; i <= 3; i += 2) {
        const column = new THREE.Mesh(columnGeometry, columnMaterial);
        column.position.set(i, 2, 2.5);
        buildingGroup.add(column);
      }

      const doorGeometry = new THREE.PlaneGeometry(1.5, 2.5);
      const doorMaterial = new THREE.MeshBasicMaterial({ color: 0x8B4513, side: THREE.DoubleSide });
      door = new THREE.Mesh(doorGeometry, doorMaterial);
      door.position.set(0, 1.25, 2.01);
      buildingGroup.add(door);

      const entryRegionGeometry = new THREE.BoxGeometry(2, 2.5, 0.5);
      const entryRegionMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0 });
      entryRegion = new THREE.Mesh(entryRegionGeometry, entryRegionMaterial);
      entryRegion.position.set(0, 1.25, 2.25);
      buildingGroup.add(entryRegion);

      // Add invisible collision walls slightly behind the visible walls
      const wallGeometry = new THREE.BoxGeometry(8, 6, 0.1);
      const wallMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });

      const frontWall = new THREE.Mesh(wallGeometry, wallMaterial);
      frontWall.position.set(0, 3, -2.05);
      buildingGroup.add(frontWall);

      const backWall = new THREE.Mesh(wallGeometry, wallMaterial);
      backWall.position.set(0, 3, 2.05);
      buildingGroup.add(backWall);

      const sideWallGeometry = new THREE.BoxGeometry(0.1, 6, 4);

      const leftWall = new THREE.Mesh(sideWallGeometry, wallMaterial);
      leftWall.position.set(-4.05, 3, 0);
      buildingGroup.add(leftWall);

      const rightWall = new THREE.Mesh(sideWallGeometry, wallMaterial);
      rightWall.position.set(4.05, 3, 0);
      buildingGroup.add(rightWall);

    } else {
      const buildingGeometry = new THREE.BoxGeometry(3, 6, 3);
      const buildingMaterial = new THREE.MeshBasicMaterial({ color: 0x808080 });
      const building = new THREE.Mesh(buildingGeometry, buildingMaterial);
      building.position.set(0, 3, 0);
      buildingGroup.add(building);

      const windowGeometry = new THREE.PlaneGeometry(0.5, 0.5);
      const windowMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00, side: THREE.DoubleSide });

      if (info !== 'Front End Project Building') {
        for (let i = 1; i <= 5; i++) {
          const window1 = new THREE.Mesh(windowGeometry, windowMaterial);
          window1.position.set(1, i, 1.6);
          buildingGroup.add(window1);

          const window2 = new THREE.Mesh(windowGeometry, windowMaterial);
          window2.position.set(-1, i, 1.6);
          buildingGroup.add(window2);
        }
      }

      const doorGeometry = new THREE.PlaneGeometry(1, 2);
      const doorMaterial = new THREE.MeshBasicMaterial({ color: 0x8B4513, side: THREE.DoubleSide });
      door = new THREE.Mesh(doorGeometry, doorMaterial);
      door.position.set(0, 1, 1.51);
      buildingGroup.add(door);

      const entryRegionGeometry = new THREE.BoxGeometry(1.5, 2.5, 0.5);
      const entryRegionMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0 });
      entryRegion = new THREE.Mesh(entryRegionGeometry, entryRegionMaterial);
      entryRegion.position.set(0, 1, 1.75);
      buildingGroup.add(entryRegion);

      // Add invisible collision walls slightly behind the visible walls
      const wallGeometry = new THREE.BoxGeometry(3, 6, 0.1);
      const wallMaterial = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 });

      const frontWall = new THREE.Mesh(wallGeometry, wallMaterial);
      frontWall.position.set(0, 3, -1.55);
      buildingGroup.add(frontWall);

      const backWall = new THREE.Mesh(wallGeometry, wallMaterial);
      backWall.position.set(0, 3, 1.55);
      buildingGroup.add(backWall);

      const sideWallGeometry = new THREE.BoxGeometry(0.1, 6, 3);

      const leftWall = new THREE.Mesh(sideWallGeometry, wallMaterial);
      leftWall.position.set(-1.55, 3, 0);
      buildingGroup.add(leftWall);

      const rightWall = new THREE.Mesh(sideWallGeometry, wallMaterial);
      rightWall.position.set(1.55, 3, 0);
      buildingGroup.add(rightWall);
    }

    if (info === 'Front End Project Building') {
      buildingGroup.children[0].material.color.set(0xffffff); // White color
      const glassWindowGeometry = new THREE.PlaneGeometry(2.5, 5);
      const glassWindowMaterial = new THREE.MeshBasicMaterial({ color: 0x87CEEB, transparent: true, opacity: 0.5 });
      const glassWindow = new THREE.Mesh(glassWindowGeometry, glassWindowMaterial);
      glassWindow.position.set(0, 3, 1.51);
      buildingGroup.add(glassWindow);
      // Adding more techy and modern elements like antennas or other structures can be added here.
    }

    if (info === 'Web Automation and Data Extraction Building') {
      const globeRadius = 2;
      const globeSegments = 8; // Reduced number of segments for even thicker strands
      const globeMaterial = new THREE.MeshBasicMaterial({ color: 0x808080 });
      const globeGeometry = new THREE.SphereGeometry(globeRadius, globeSegments, globeSegments);
      const wireframe = new THREE.WireframeGeometry(globeGeometry);
      const globe = new THREE.LineSegments(wireframe, new THREE.LineBasicMaterial({ color: 0x808080, linewidth: 5 })); // Thick grey lines
      globe.position.set(0, 8, 0);
      buildingGroup.add(globe);

      const wwwTexture = new THREE.TextureLoader().load('/mnt/data/image.png');
      const wwwMaterial = new THREE.MeshBasicMaterial({ map: wwwTexture, transparent: true, opacity: 1 });
      const wwwGeometry = new THREE.PlaneGeometry(globeRadius * 1.5, globeRadius * 1.5);
      const wwwMesh = new THREE.Mesh(wwwGeometry, wwwMaterial);
      wwwMesh.position.set(0, 8, 0);
      wwwMesh.rotation.x = Math.PI / 2;
      buildingGroup.add(wwwMesh);
    }
    
    if (info === 'AI/ML and Data Science Building') {
      buildingGroup.children[0].material.color.set(0x000000); // Dark color for a techy look
      const neonMaterial = new THREE.MeshBasicMaterial({ color: 0x00FF00 });
      const neonSignGeometry = new THREE.PlaneGeometry(3, 1);
      const neonSign = new THREE.Mesh(neonSignGeometry, neonMaterial);
      neonSign.position.set(0, 6, 1.55);
      buildingGroup.add(neonSign);
    
      // Simplified Robot Arm
      const armMaterial = new THREE.MeshBasicMaterial({ color: 0xFFA500 });
    
      // First part of the arm
      const arm1Geometry = new THREE.BoxGeometry(0.5, 2, 0.5);
      const arm1 = new THREE.Mesh(arm1Geometry, armMaterial);
      arm1.position.set(2.75, 5.5, 0);
      arm1.rotation.z = Math.PI / 2; // Arm pointing right
      buildingGroup.add(arm1);
    
      // Joint
      const jointGeometry = new THREE.SphereGeometry(0.25, 32, 32);
      const joint = new THREE.Mesh(jointGeometry, new THREE.MeshBasicMaterial({ color: 0x333333 }));
      joint.position.set(3.75, 5.5, 0);
      buildingGroup.add(joint);
    
      // Second part of the arm
      const arm2Geometry = new THREE.BoxGeometry(0.5, 1.5, 0.5);
      const arm2 = new THREE.Mesh(arm2Geometry, armMaterial);
      arm2.position.set(4.5, 5, 0); // Moved down to attach to the first part
      arm2.rotation.z = Math.PI / 4; // Angled upwards
      buildingGroup.add(arm2);
    
      // Hand/Claw
      const clawGeometry = new THREE.BoxGeometry(0.2, 0.8, 0.2);
      const claw1 = new THREE.Mesh(clawGeometry, new THREE.MeshBasicMaterial({ color: 0x333333 }));
      claw1.position.set(5.25, 4.25, 0.25); // Attached to the end of the second arm
      claw1.rotation.z = Math.PI / 4;
      buildingGroup.add(claw1);
    
      const claw2 = new THREE.Mesh(clawGeometry, new THREE.MeshBasicMaterial({ color: 0x333333 }));
      claw2.position.set(5.25, 4.25, -0.25); // Attached to the end of the second arm
      claw2.rotation.z = Math.PI / 4;
      buildingGroup.add(claw2);
    }

    buildingGroup.position.set(x, 0, z);
    buildingGroup.userData = {
      info,
      path,
      door,
      exit,
      entryRegion,
      receptionistMessage,
      projects
    };

    // Add floating text
    createText(info, new THREE.Vector3(x, 6, z));

    const handleNavigation = () => {
      const serializableBuilding = {
        info: buildingGroup.userData.info,
        path: buildingGroup.userData.path,
        exit: buildingGroup.userData.exit,
        receptionistMessage,
        projects: buildingGroup.userData.projects
      };
      console.log('Navigating to lobby with building data:', serializableBuilding);
      setBuilding(serializableBuilding); // Set building data in context
      navigate(path, { state: { building: serializableBuilding } });
    };

    buildingGroup.callback = handleNavigation;

    buildingGroup.traverse((child) => {
      if (child.isMesh) {
        child.userData = { buildingGroup };
        child.callback = handleNavigation;
      }
    });

    buildingGroup.addEventListener('click', handleNavigation);

    scene.add(buildingGroup);
    buildings.push(buildingGroup);
  };

  buildingData.forEach(({ x, z, info, path, isSchool, exit, receptionistMessage, projects }) =>
    createBuilding(x, z, info, path, isSchool, exit, receptionistMessage, projects)
  );

  return buildings;
};
