import React, { createContext, useState, useEffect } from 'react';

export const XPContext = createContext();

const XPProvider = ({ children }) => {
  const [xp, setXP] = useState(() => {
    const savedXP = localStorage.getItem('xp');
    return savedXP ? parseInt(savedXP, 10) : 0;
  });
  const maxXP = 70; // Update maxXP to reflect the total for 14 projects

  useEffect(() => {
    localStorage.setItem('xp', xp.toString());
  }, [xp]);

  const addXP = (points) => {
    setXP(prevXP => Math.min(prevXP + points, maxXP));
  };

  return (
    <XPContext.Provider value={{ xp, setXP, maxXP, addXP }}>
      {children}
    </XPContext.Provider>
  );
};

export default XPProvider;
