import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import visualization1 from '../images/Screenshot 2024-07-06 145658.png';
import visualization2 from '../images/Screenshot 2024-07-06 145843.png';
import visualization3 from '../images/Screenshot 2024-07-06 150645.png'; // Import the third image
import visualization4 from '../images/Screenshot 2024-07-06 145918.png'; // Import the fourth image
import { XPContext } from '../XPProvider'; // Import XPContext


const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <p>This project, completed as part of the SI 305: Introduction to Information Analysis course, investigates the influence of seasonal patterns, weather conditions, and population growth on the presence of E. coli in Michigan water bodies from 2019 to 2022. It forms the final deliverable for the course, contributing significantly to the overall grade. By conducting extensive analyses, the study uncovers significant correlations between precipitation and E. coli levels, revealing a critical impact of rainfall on water contamination. This contrasts with the more variable and less pronounced relationship between temperature and E. coli.</p>
        <p>The study also explores the relationship between population changes and E. coli levels, focusing on the Ann Arbor area. It highlights a noticeable correlation, indicating that human activity significantly affects water quality. This research underscores the importance of monitoring environmental factors and human activity to manage and improve water quality effectively.</p>
        <p>By combining statistical analyses and visual representations, the study provides a comprehensive understanding of how weather patterns and urbanization contribute to water contamination. These insights are crucial for developing targeted water treatment strategies, enhancing public health measures, and ensuring sustainable water quality management in Michigan.</p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>This project includes a comprehensive data analysis feature that examines weather patterns (precipitation, temperature, snowfall) and their correlation with E. coli levels. It also investigates the relationship between population growth and E. coli contamination, focusing on Ann Arbor. The seasonal pattern analysis reveals significant correlations between precipitation events and spikes in E. coli levels, while highlighting the less pronounced impact of temperature extremes and snowfall.</p>
        <p>Another key feature is the population impact study, which visualizes trends indicating how urbanization and human activity contribute to increased E. coli presence in water bodies. The project employs robust statistical methods, using Pearson correlation coefficients and regression models to measure and quantify the relationships between variables. A lagged analysis feature examines the delayed effects of rainfall on E. coli levels, providing deeper insights into contamination dynamics.</p>
        <p>Visual representations are a significant aspect of this project, with time series graphs, correlation matrices, and dual-axis plots developed to convey data trends and analysis outcomes clearly. Two notable visualizations include a time series graph showing monthly averages of E. coli Most Probable Number (MPN) alongside precipitation, snowfall, maximum temperature (TMAX), and minimum temperature (TMIN) from 2019 to 2022, and a dual-axis plot illustrating the trends in population growth and E. coli levels in Ann Arbor from 2018 to 2022.</p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <img src={visualization1} alt="Visualization 1: Time series graph showing monthly averages of E. coli MPN and weather variables" style={{ width: '30%', height: 'auto' }} />
          <img src={visualization2} alt="Visualization 2: Dual-axis plot illustrating population growth and E. coli levels in Ann Arbor" style={{ width: '30%', height: 'auto' }} />
          <img src={visualization4} alt="Visualization 4: Regression analysis showing E. coli levels with PRCP, TMIN, and TMAX" style={{ width: '30%', height: 'auto' }} />
        </div>
        <p>Public health implications are highlighted, emphasizing the importance of immediate and proactive measures following rainfall events to mitigate E. coli contamination risks. The project also provides actionable recommendations for enhanced monitoring and infrastructure improvements based on the study’s findings. Overall, this project integrates comprehensive data analysis, visual representations, and robust statistical methods to offer valuable insights into the factors influencing E. coli presence in Michigan water bodies.</p>
      </>
    )
  },
  {
    id: 'technology',
    title: 'Technology Used',
    content: (
      <>
        <p>This project utilizes a variety of technologies to conduct comprehensive data analysis and visualization. The primary tools and technologies employed include:</p>
        <p><strong>Software and Programming Languages</strong>:
        <ul>
          <li>Python: The primary programming language used for data analysis, statistical calculations, and creating visualizations.</li>
          <li>Jupyter Notebooks: Used for organizing and running the analysis, as well as documenting the project workflow.</li>
        </ul>
        </p>
        <p><strong>Data Analysis and Visualization Libraries</strong>:
        <ul>
          <li>Pandas: For data manipulation and analysis, including reading data files, cleaning data, and performing statistical operations.</li>
          <li>NumPy: Used for numerical operations and managing arrays.</li>
          <li>Matplotlib: For creating static, interactive, and animated visualizations.</li>
          <li>Seaborn: A visualization library based on Matplotlib, used for making statistical graphics.</li>
        </ul>
        </p>
        <p><strong>Statistical Analysis Tools</strong>:
        <ul>
          <li>Scipy: Used for performing statistical tests and calculations, such as Pearson correlation coefficients.</li>
          <li>Statsmodels: For conducting regression analysis and other statistical models.</li>
        </ul>
        </p>
        <p><strong>Data Sources</strong>:
        <ul>
          <li>Weather Data: Obtained from reliable sources such as NOAA or local weather stations, providing data on precipitation, temperature, and snowfall.</li>
          <li>E. coli Data: Sourced from environmental and public health databases, detailing E. coli levels in various water bodies.</li>
          <li>Population Data: Acquired from census data and local governmental records, particularly focusing on Ann Arbor.</li>
        </ul>
        </p>
        <p><strong>Data Storage and Management</strong>:
        <ul>
          <li>CSV Files: For storing raw and cleaned data, ensuring easy access and manipulation.</li>
          <li>SQLite Database: Used to organize and manage data efficiently, facilitating easy retrieval and analysis.</li>
        </ul>
        </p>
        <p><strong>Development Environment</strong>:
        <ul>
          <li>Vocareum: The platform used for running Jupyter Notebooks and managing the project workflow.</li>
        </ul>
        </p>
        <p><strong>Documentation and Reporting</strong>:
        <ul>
          <li>Google Docs: For writing and formatting the final report.</li>
          <li>GitHub: Used for version control and collaboration, ensuring the project is well-documented and easily accessible.</li>
        </ul>
        </p>
        <p>These technologies combined to create a robust framework for analyzing the impact of weather patterns and population changes on E. coli levels in Michigan water bodies, ensuring accurate and insightful results.</p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>One of the primary challenges encountered during this project was the collection and integration of diverse datasets. Sourcing accurate and comprehensive weather data, E. coli levels, and population statistics required navigating various databases and ensuring consistency in data formats. Additionally, cleaning and preprocessing the data to handle missing values, outliers, and inconsistencies was time-consuming but crucial for reliable analysis.</p>
        <p>Another significant challenge was the statistical analysis, particularly in determining the appropriate methods to explore correlations and causations between variables. The complexity of regression models and the need for robustness checks to validate findings added layers of difficulty. Interpreting the results required careful consideration of confounding factors and potential biases that could affect the conclusions drawn from the data.</p>
        <p>Visualizing the data effectively posed its own set of challenges. Creating clear, informative, and aesthetically pleasing visualizations involved selecting the right types of graphs, managing large datasets, and ensuring that the visualizations accurately represented the findings without oversimplifying complex relationships. This was crucial for conveying the insights effectively to both technical and non-technical audiences.</p>
        <p>The project also faced challenges related to lagged data analysis. Initially hypothesizing that rainfall would have a delayed impact on E. coli levels required conducting a lagged correlation analysis. Contrary to expectations, the findings suggested an immediate response of E. coli levels to rainfall, necessitating a re-evaluation of the initial assumptions and additional analysis to understand the underlying mechanisms.</p>
        <img src={visualization3} alt="Visualization 3: showing lagged vs non-lagged regression analysis" style={{ width: '50%', height: 'auto', display: 'block', margin: '20px auto' }} />
        <p>Managing the project timeline and ensuring the final report met the requirements of the SI 305 course was another challenge. Balancing the thoroughness of the analysis with the assignment's deadlines required careful planning and time management. Additionally, adhering to the detailed format and content specifications of the final deliverable necessitated meticulous attention to detail.</p>
        <p>Despite these challenges, overcoming them provided valuable lessons in data analysis, statistical modeling, and project management, ultimately contributing to a comprehensive understanding of the factors influencing E. coli levels in Michigan water bodies.</p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>This project provided a thorough analysis of the influence of seasonal patterns, weather conditions, and population growth on the presence of E. coli in Michigan water bodies from 2019 to 2022. By leveraging robust statistical methods and comprehensive data visualization techniques, we uncovered significant correlations between precipitation and E. coli levels, highlighting the critical impact of rainfall on water contamination. Additionally, we identified a noticeable relationship between population growth and E. coli levels, emphasizing the role of human activity in water quality management.</p>
        <p>Our findings underscore the importance of continuous monitoring and proactive measures to mitigate E. coli contamination, especially following rainfall events. The analysis revealed that immediate action is necessary to address the spike in E. coli levels after heavy rains, suggesting the need for enhanced rainfall event monitoring and improved stormwater treatment infrastructure. Furthermore, the study highlights the value of integrating temperature data into predictive models, although precipitation emerged as the more significant factor.</p>
        <p>Challenges such as data collection, statistical analysis, and effective visualization were met with strategic solutions, resulting in valuable insights that inform public health decisions and urban planning. The project's recommendations, including enhanced monitoring, infrastructure development, and public education campaigns, aim to safeguard water quality and public health in Michigan.</p>
        <p>Moving forward, the continuation of this research with a broader dataset and advanced analytical techniques could provide deeper insights into the dynamic relationship between environmental factors and water contamination. The lessons learned from this project contribute to a foundational understanding of E. coli presence in water bodies and pave the way for future studies and interventions aimed at improving water quality and ensuring public safety.</p>
      </>
    )
  }
];

const EcoliPrediction = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default EcoliPrediction;
