import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/Screenshot 2024-07-11 121656.png';
import visualization2 from '../images/Screenshot 2024-07-11 122046.png';



const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <p>
          This project is a comprehensive tool designed to automate the scraping, parsing, and storage of vehicle listings from Facebook Marketplace. It leverages a combination of web scraping techniques using Selenium, data parsing with BeautifulSoup, and natural language processing with OpenAI's GPT model to extract detailed information about vehicles listed for sale. Additionally, it organizes and cleans the collected data in an SQLite database, providing a structured and searchable format for further analysis.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization2} alt="Quora Upvotes" style={{ width: '35%', height: 'auto' }} />
          </div>
        <p>
          The primary objective of the project is to facilitate the automated collection of vehicle listings from Facebook Marketplace, extract relevant information such as price, year, make, model, and other specifications, and store this information for future use. This system is particularly useful for compiling large datasets of vehicle listings, which can be used for market analysis, price comparisons, and other research purposes.
        </p>
        <p>
          By integrating advanced web scraping techniques and machine learning models, this project provides a robust solution for data collection and processing from dynamic web sources. It demonstrates the practical application of automation in data collection and the use of AI for data extraction and classification, showcasing how these technologies can be combined to solve real-world problems efficiently.
        </p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>
          This project offers a range of features designed to automate the collection and processing of vehicle listings from Facebook Marketplace:
        </p>
        <p>
          <strong>Automated Login:</strong> The project uses Selenium to automate the login process to Facebook, ensuring seamless access to Marketplace listings without manual intervention.
        </p>
        <p>
          <strong>Web Scraping:</strong> The tool collects vehicle listings by navigating through Facebook Marketplace pages and extracting relevant information using BeautifulSoup. This includes handling dynamic content loading and interacting with page elements.
        </p>
        <p>
          <strong>Dynamic Location and Radius Setting:</strong> Users can specify the location and search radius for scraping, enabling targeted data collection based on geographical preferences.
        </p>
        <p>
          <strong>Data Extraction:</strong> The project extracts detailed information about each vehicle listing, including price, year, make, model, odometer reading, and location. This information is parsed from the raw HTML content.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="Quora Upvotes" style={{ width: '35%', height: 'auto' }} />

          </div>
        <p>
          <strong>Natural Language Processing:</strong> By leveraging OpenAI's GPT model, the project classifies and extracts structured information from unstructured text descriptions of vehicle listings, improving the accuracy and depth of data extracted.
        </p>
        <p>
          <strong>Data Storage:</strong> The extracted data is stored in an SQLite database, providing a structured format that is easy to query and analyze for further use or research.
        </p>
        <p>
          <strong>Data Cleaning:</strong> The project includes functionality to clean the database by removing invalid entries, duplicates, and listings that do not match specified criteria, ensuring high-quality data.
        </p>
        <p>
          <strong>Progress Tracking:</strong> During data insertion and scraping processes, the project displays progress updates, giving feedback on the number of listings processed and stored, and ensuring transparency in operations.
        </p>
        <p>
          These features make the project a robust and efficient tool for automating the collection, extraction, and management of vehicle listings from Facebook Marketplace, providing valuable insights and data for various applications.
        </p>
      </>
    )
  },
  {
    id: 'technology-used',
    title: 'Technology Used',
    content: (
      <>
        <p>
          The project leverages a combination of advanced technologies to achieve its functionality:
        </p>
        <p>
          <strong>Selenium:</strong> A powerful web automation tool used for browsing Facebook Marketplace, handling user interactions, and collecting raw HTML content. Selenium allows for automated web browsing and interaction, making it possible to log in to Facebook, navigate to the Marketplace, and interact with page elements dynamically.
        </p>
        <p>
          <strong>BeautifulSoup:</strong> A Python library for parsing HTML and XML documents. BeautifulSoup is used to extract specific elements from the raw HTML collected by Selenium, such as vehicle listings and their details, providing a structured way to navigate and search through the HTML content.
        </p>
        <p>
          <strong>OpenAI GPT:</strong> A state-of-the-art natural language processing model used to classify and extract detailed information from the unstructured text descriptions of vehicle listings. This enhances the accuracy and completeness of the data extracted from listings.
        </p>
        <p>
          <strong>SQLite:</strong> A lightweight database management system used to store the extracted data in a structured format. SQLite enables efficient querying and analysis of the collected data, making it easy to manage and utilize the information for further research or applications.
        </p>
        <p>
          <strong>Pandas and NumPy:</strong> Essential libraries for data manipulation and numerical operations. These libraries are used for processing, cleaning, and analyzing the collected data, providing powerful tools for data handling and transformation.
        </p>
        <p>
          <strong>PyCaw:</strong> A library for controlling system audio. Although primarily used for volume control in other applications, PyCaw demonstrates the project's capability to integrate with system-level functionalities.
        </p>
        <p>
          <strong>PyAutoGUI:</strong> A library for automating keyboard and mouse actions, used to handle dynamic interactions on web pages. PyAutoGUI allows for precise control over input actions, facilitating tasks like setting search parameters and interacting with page elements.
        </p>
        <p>
          <strong>Webdriver Manager:</strong> A tool to automatically manage browser drivers, ensuring compatibility and ease of use. Webdriver Manager simplifies the setup and maintenance of browser drivers, reducing potential issues related to driver versions and compatibility.
        </p>
        <p>
          These technologies work together to provide a robust and efficient system for automating the collection, extraction, and management of vehicle listings from Facebook Marketplace. By combining web automation, data parsing, machine learning, and database management, the project demonstrates the effective integration of multiple technologies to solve complex data collection and processing challenges.
        </p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>
          Developing this automated scraping and data extraction tool for Facebook Marketplace presented several significant challenges:
        </p>
        <p>
          <strong>Website Structure and Dynamic Content:</strong> Facebook Marketplace's dynamic and frequently changing structure made it challenging to develop a reliable scraping method. The use of JavaScript to load content dynamically required careful handling with Selenium to ensure all elements were fully loaded before attempting to extract data.
        </p>
        <p>
          <strong>Login and Interaction Automation:</strong> Automating the login process and interactions on Facebook involved dealing with security measures like CAPTCHA and dynamic content loading. Ensuring the bot could reliably log in and navigate the Marketplace without manual intervention required extensive testing and adjustments.
        </p>
        <p>
          <strong>Data Extraction and Parsing:</strong> Extracting meaningful information from unstructured text descriptions posed a challenge. Vehicle listings often contained incomplete or inconsistently formatted information. Leveraging OpenAI's GPT model for natural language processing helped to improve the accuracy and completeness of data extraction, but required fine-tuning and validation.
        </p>
        <p>
          <strong>Maintaining Session and Handling Timeouts:</strong> Keeping the session active and handling timeouts or unexpected disconnections was critical for ensuring continuous operation. Implementing robust error handling and retry mechanisms was essential to maintain a stable and reliable scraping process.
        </p>
        <p>
          <strong>Data Cleaning and Validation:</strong> Ensuring the quality and accuracy of the extracted data required implementing extensive data cleaning and validation routines. This involved removing duplicates, handling missing values, and validating extracted information to meet specific criteria.
        </p>
        <p>
          <strong>Performance Optimization:</strong> Balancing the need for thorough data extraction with the performance and speed of the scraping process was a key challenge. Optimizing the scraping and data processing workflows to handle large volumes of listings efficiently without overloading the system or getting blocked by Facebook's rate limiting was crucial.
        </p>
        <p>
          <strong>Database Management:</strong> Storing and managing the extracted data in an SQLite database involved challenges related to database schema design, handling large datasets, and ensuring efficient querying and retrieval of information. Implementing mechanisms for data cleaning, deduplication, and validation within the database added another layer of complexity.
        </p>
        <p>
          These challenges required a combination of technical skills, including web automation, data parsing, natural language processing, database management, and performance optimization. Overcoming these obstacles provided valuable insights into the complexities of web scraping and data extraction, and highlighted the importance of robust error handling, validation, and optimization techniques.
        </p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>
          In conclusion, this project demonstrates the powerful combination of web scraping, natural language processing, and data management to automate the collection and extraction of vehicle listings from Facebook Marketplace. By integrating Selenium for web automation, BeautifulSoup for HTML parsing, OpenAI's GPT for natural language processing, and SQLite for data storage, the project effectively addresses the challenges of dynamically structured web content and unstructured data.
        </p>
        <p>
          The tool not only automates the tedious task of manually collecting vehicle listings but also ensures that the extracted data is structured, accurate, and ready for analysis. It showcases the practical application of advanced technologies to solve real-world problems, particularly in the domain of market analysis and research.
        </p>
        <p>
          The development process involved overcoming significant technical challenges, including handling dynamic web content, automating interactions, ensuring data quality, and optimizing performance. These experiences provided valuable learning experiences and highlighted the importance of robust error handling, data validation, and efficient processing workflows.
        </p>
        <p>
          Looking ahead, the project can be further enhanced by expanding its capabilities to scrape additional online marketplaces, integrating more advanced machine learning models for better data extraction, and implementing real-time data monitoring and alerting systems. Additionally, exploring the use of cloud-based solutions for scaling the scraping and data processing tasks could improve the tool's efficiency and scalability.
        </p>
        <p>
          Overall, this project underscores the potential of automation and AI in transforming data collection processes and provides a solid foundation for further innovation and development in the field of web scraping and data extraction.
        </p>
      </>
    )
  }
];

const CarDataCompiler = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default CarDataCompiler;
