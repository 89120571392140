import React, { useEffect, useRef, useState, useContext } from 'react';
import * as THREE from 'three';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { setupScene } from './SceneSetup';
import { setupControls } from './Controls';
import { addGrid } from './Grid';
import { addGround } from './Ground';
import { addSidewalk } from './Sidewalk';
import { addStreet } from './Street';
import { addCars, animateCars } from './Cars';
import { addBuildings } from './Buildings';
import { addPerson, animatePerson } from './Person';
import { handleMovement, checkCollisions } from './Movement';
import { XPContext } from './XPProvider';
import XPBar from './XPBar';
import AtlasMajorProject from './projects/AtlasMajorProject';
import TheCityScapeItself from './projects/TheCityScapeItself';
import EcoFlux from './projects/EcoFlux';
import EcoliPrediction from './projects/EcoliPrediction';
import StrategyConsultingPrep from './projects/StrategyConsultingPrep';
import Project6 from './Project6';
import OpenTableReservationBot from './projects/OpenTableReservationBot';
import ProfileBooster from './projects/ProfileBooster';
import AIStockSentimentBot from './projects/AIStockSentimentBot';
import ARAndComputerVisionExploration from './projects/ARAndComputerVisionExploration';
import CarDataCompiler from './projects/CarDataCompiler';
import CarValueFinder from './projects/CarValueFinder';
import AISmartGlasses from './projects/AISmartGlasses';
import AIBlogGen from './projects/AIBlogGen';
import LinkedInScraper from './projects/LinkedInScraper';
import { Analytics } from "@vercel/analytics/react";
import Lobby from './Lobby';
import BuildingProvider, { BuildingContext } from './BuildingContext';
import './styles.css';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const personRef = useRef(null);
  const cameraRef = useRef(null);
  const controlsRef = useRef(null);
  const { building, setBuilding } = useContext(BuildingContext); // Get building context
  const [defaultCameraPosition] = useState(new THREE.Vector3(0, 20, 25));
  const [initialSpawnPosition] = useState(new THREE.Vector3(0, 0.5, 0));
  const [showInstructions, setShowInstructions] = useState(true);
  const [disableMovement, setDisableMovement] = useState(false);
  const { xp, maxXP } = useContext(XPContext);
  const keysPressed = useRef({ ArrowUp: false, ArrowDown: false, ArrowLeft: false, ArrowRight: false }).current;

  useEffect(() => {
    if (location.pathname === '/') {
      const { scene, camera, renderer, container } = setupScene();
      const controls = setupControls(camera, renderer, setShowInstructions);
      controlsRef.current = controls;
  
      addGrid(scene);
      addGround(scene);
      addSidewalk(scene);
      addStreet(scene);
  
      const cars = addCars(scene);
      const buildings = addBuildings(scene, navigate, setBuilding); // Pass setBuilding callback
      const person = addPerson(scene);
      personRef.current = person;
      cameraRef.current = camera;
  
      camera.position.copy(defaultCameraPosition);
      camera.lookAt(0, 0, 0);
  
      const handleKeyDown = (event) => handleMovement(event, keysPressed, disableMovement);
      const handleKeyUp = (event) => handleMovement(event, keysPressed, disableMovement);
  
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
  
      const handleMouseClick = (event) => {
        event.preventDefault();
        const mouse = new THREE.Vector2(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1
        );
  
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children, true);
  
        if (intersects.length > 0) {
          const object = intersects[0].object;
          if (object.userData && object.userData.buildingGroup && object.callback) {
            object.callback();
          }
        }
      };
  
      window.addEventListener('click', handleMouseClick);
  
      const animate = function () {
        requestAnimationFrame(animate);
        animatePerson(keysPressed, person);
        checkCollisions(person, buildings, navigate, keysPressed, setDisableMovement, setBuilding); // Pass setBuilding
        animateCars(cars);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
  
      person.position.copy(initialSpawnPosition);
  
      return () => {
        renderer.dispose();
        container.removeChild(renderer.domElement);
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
        window.removeEventListener('click', handleMouseClick);
        controls.removeEventListener('start', () => setShowInstructions(false));
      };
    }
  }, [navigate, defaultCameraPosition, initialSpawnPosition, location.pathname, disableMovement]);

  useEffect(() => {
    if (location.state?.resetPosition) {
      const exit = location.state.exit || initialSpawnPosition;
      if (personRef.current) {
        personRef.current.position.set(exit.x, 0.5, exit.z);
      } else {
        personRef.current.position.copy(initialSpawnPosition);
      }
      console.log('Resetting position to:', exit);
      handleResetCamera();
    }
  }, [location.state, initialSpawnPosition]);

  useEffect(() => {
    if (location.pathname === '/lobby' && location.state?.building) {
      setBuilding(location.state.building);  // Set building data
      const exit = location.state.building.exit || initialSpawnPosition;
      if (personRef.current) {
        personRef.current.position.set(exit.x, 0.5, exit.z);
      }
      console.log('Entering building and setting position to exit:', exit);
    }
  }, [location.pathname, location.state, initialSpawnPosition, setBuilding]);

  const handleResetCamera = () => {
    if (controlsRef.current && cameraRef.current) {
      controlsRef.current.reset();
      cameraRef.current.position.copy(defaultCameraPosition);
      cameraRef.current.lookAt(0, 0, 0);
    }
  };

  const handleUnlockMovement = () => {
    setDisableMovement(false);
    keysPressed.navigationTriggered = false;  // Reset the navigationTriggered flag when unlocking movement
  };

  return (
    <BuildingProvider>
      <div id="app-container">
        <Routes>
          <Route
            path="/"
            element={
              <div id="threejs-container" style={{ width: '100vw', height: '100vh' }}>
                {location.pathname === '/' && (
                  <div className="reset-camera-container">
                    <button className="button" onClick={handleResetCamera}>
                      Reset Camera
                    </button>
                  </div>
                )}
                {showInstructions && (
                  <div className="instructions-container">
                    <div className="info-message">
                      Pinch the trackpad to zoom or click and drag to move around.
                    </div>
                  </div>
                )}
                <XPBar />
                <Analytics />
              </div>
            }
          />
          <Route path="/lobby" element={<Lobby onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/AtlasMajorProject" element={<AtlasMajorProject onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/TheCityScapeItself" element={<TheCityScapeItself onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/EcoFlux" element={<EcoFlux onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/EcoliPrediction" element={<EcoliPrediction onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/StrategyConsultingPrep" element={<StrategyConsultingPrep onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/Project6" element={<Project6 onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/OpenTableReservationBot" element={<OpenTableReservationBot onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/ProfileBooster" element={<ProfileBooster onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/AIStockSentimentBot" element={<AIStockSentimentBot onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/ARAndComputerVisionExploration" element={<ARAndComputerVisionExploration onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/CarDataCompiler" element={<CarDataCompiler onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/CarValueFinder" element={<CarValueFinder onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/AISmartGlasses" element={<AISmartGlasses onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/AIBlogGen" element={<AIBlogGen onUnlockMovement={handleUnlockMovement} />} />
          <Route path="/projects/LinkedInScraper" element={<LinkedInScraper onUnlockMovement={handleUnlockMovement} />} />

        </Routes>
        <div className="xp-display">
          XP: {xp}/{maxXP}
        </div>
      </div>
    </BuildingProvider>
  );
};

export default App;
