import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/Screenshot 2024-07-10 143135.png';



const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <p>
          This project is an AI-driven stock trading bot designed to analyze and trade AI-related stocks. Using a combination of financial data, sentiment analysis, and machine learning models, the bot makes informed trading decisions to optimize portfolio performance. The primary goal of the project is to automate the trading process, leveraging real-time data and advanced algorithms to maximize returns.
        </p>
        <p>
          The bot monitors a selection of AI-related tickers, including NVDA, AMD, GOOGL, MSFT, TSLA, IBM, AAPL, AMZN, META, and INTC, as well as the S&P 500 index. It fetches live market data and sentiment data from various sources, such as news articles, social media posts, and financial analysis platforms like Seeking Alpha. Sentiment analysis is performed using the VADER sentiment analyzer from the NLTK library.
        </p>
        <p>
          To make trading decisions, the bot calculates fundamental indicators (e.g., P/E ratio, ROE, current ratio), technical indicators (e.g., SMA, RSI, volatility), and sentiment scores. It uses these indicators as features for machine learning models, including RandomForestClassifier, GradientBoostingClassifier, and MLPClassifier. The models are trained on historical data and optimized using techniques like GridSearchCV and SMOTE for handling imbalanced data.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="Quora Upvotes" style={{ width: '70%', height: 'auto' }} />

          </div>
        <p>
          The bot continuously updates its strategy and model based on new data, ensuring that it adapts to changing market conditions. It simulates trading by buying or selling stocks based on model predictions, maintaining a portfolio and tracking its value over time. This project showcases the integration of financial analysis, sentiment analysis, and machine learning to create a sophisticated automated trading system.
        </p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
        <p>
          The AI-driven stock trading bot offers a comprehensive suite of features designed to optimize trading decisions and maximize returns. One of its primary capabilities is live market data analysis. The bot continuously fetches live market data for AI-related stocks and the S&P 500 index, updating prices in real-time to ensure that trading decisions are based on the most current information available.
        </p>
        <p>
          In addition to market data, the bot performs sentiment analysis by scraping news articles, social media posts, and content from Seeking Alpha. Using the VADER sentiment analyzer, it gauges the market sentiment for each stock, providing a nuanced understanding of how the public and analysts view these companies.
        </p>
        <p>
          The bot also calculates a variety of fundamental and technical indicators to assess the performance and potential of each stock. Fundamental indicators include metrics like P/E ratio, ROE, and current ratio, while technical indicators cover aspects like SMA, RSI, and volatility. These indicators form the basis of the bot's analytical approach, allowing it to make informed predictions about stock movements.
        </p>
        <p>
          Machine learning models are at the heart of the bot's decision-making process. It employs several models, including RandomForestClassifier, GradientBoostingClassifier, and MLPClassifier, to predict stock movements based on a blend of historical and real-time data. These models are fine-tuned using GridSearchCV and enhanced with techniques like SMOTE to handle imbalanced datasets effectively.
        </p>
        <p>
          The bot simulates automated trading by executing buy and sell orders based on model predictions, managing a virtual portfolio aimed at maximizing returns. To ensure it remains effective in varying market conditions, the bot regularly updates its trading strategy and retrains its models with new data.
        </p>
        <p>
          Robust execution is achieved through comprehensive error handling and retry mechanisms, which help the bot maintain functionality even when faced with data fetching issues or website changes. Furthermore, the bot stores historical data for ongoing analysis and model training, maintaining a detailed record of past market data and sentiment scores.
        </p>
        <p>
          Together, these features create a sophisticated and adaptable trading system that leverages financial data, sentiment analysis, and machine learning to optimize stock trading decisions.
        </p>
      </>
    )
  },
  {
    id: 'technology-used',
    title: 'Technology Used',
    content: (
      <>
        <p>
          This project leverages a diverse set of technologies to create an effective and sophisticated AI-driven stock trading bot:
        </p>
        <p>
          <strong>Python</strong>: The core programming language used for scripting and data analysis, chosen for its simplicity and extensive libraries.
        </p>
        <p>
          <strong>yFinance</strong>: A Python library used to fetch live market data and historical stock prices, providing the financial data necessary for analysis and trading.
        </p>
        <p>
          <strong>Pandas and NumPy</strong>: Essential libraries for data manipulation and numerical computations, used to process and analyze market data.
        </p>
        <p>
          <strong>Requests and BeautifulSoup</strong>: Tools for web scraping, employed to gather sentiment data from news articles, social media posts, and Seeking Alpha content.
        </p>
        <p>
          <strong>NLTK (Natural Language Toolkit)</strong>: Specifically, the VADER sentiment analyzer from NLTK is used to perform sentiment analysis on textual data.
        </p>
        <p>
          <strong>scikit-learn</strong>: A machine learning library used for building, training, and optimizing models. It includes tools for classification, regression, and clustering.
        </p>
        <p>
          <strong>Imbalanced-learn (imblearn)</strong>: A library that provides tools for handling imbalanced datasets, such as SMOTE (Synthetic Minority Over-sampling Technique).
        </p>
        <p>
          <strong>datetime and time</strong>: Standard Python libraries used for handling dates, times, and implementing time-based operations.
        </p>
        <p>
          <strong>OS</strong>: A standard library for interacting with the operating system, used for file handling and data storage.
        </p>
        <p>
          These technologies are integrated to form a robust system capable of real-time data processing, sentiment analysis, and machine learning model execution. The combination of financial data analysis, web scraping, and advanced machine learning techniques ensures that the trading bot can make informed decisions and adapt to changing market conditions.
        </p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>
          Developing the AI-driven stock trading bot presented several challenges. One of the primary difficulties was handling the dynamic nature of web data. Websites frequently change their structures, which required robust web scraping techniques and constant updates to the scraping scripts to ensure accurate data retrieval. Managing multiple data sources and integrating them into a cohesive system also posed a significant challenge, as it involved dealing with inconsistencies and varying data formats.
        </p>
        <p>
          Another major challenge was the complexity of financial data analysis. Calculating and interpreting fundamental and technical indicators required a deep understanding of financial concepts and the ability to implement these calculations programmatically. Ensuring the accuracy of these indicators was crucial, as they directly influenced the trading decisions made by the bot.
        </p>
        <p>
          Sentiment analysis added another layer of complexity. Processing and analyzing textual data from news articles, social media posts, and financial websites required sophisticated natural language processing techniques. The sentiment scores generated had to be accurate and reflective of market sentiment to be useful in trading decisions. This necessitated thorough testing and validation of the sentiment analysis algorithms.
        </p>
        <p>
          Machine learning model development and optimization were also challenging. Selecting the right models, tuning their hyperparameters, and handling imbalanced datasets required extensive experimentation and validation. Implementing techniques like SMOTE to balance the dataset and GridSearchCV for hyperparameter tuning was essential to improve the model’s performance and reliability.
        </p>
        <p>
          Furthermore, ensuring real-time performance was critical. The bot needed to fetch live data, process it, and make trading decisions promptly to take advantage of market opportunities. This required efficient coding practices and optimization of data processing pipelines to minimize latency.
        </p>
        <p>
          Lastly, simulating trading and managing a virtual portfolio involved accurately modeling buy and sell decisions, handling transaction costs, and ensuring the integrity of the simulation. Regularly updating the trading strategy and models based on new data ensured that the bot remained effective in varying market conditions.
        </p>
        <p>
          These challenges provided valuable learning experiences in web scraping, financial analysis, sentiment analysis, machine learning, and real-time system performance, culminating in the development of a robust and adaptable trading bot.
        </p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>
          In conclusion, the AI-driven stock trading bot showcases the power of combining financial data analysis, sentiment analysis, and machine learning to create an automated trading system. This project not only automates the trading process but also continuously adapts to market conditions by leveraging real-time data and advanced algorithms.
        </p>
        <p>
          The development of this bot was a significant technical achievement, involving the integration of various technologies and methodologies. It highlighted my ability to handle complex web interactions, perform sophisticated data analysis, and implement machine learning models effectively. The challenges faced during the development process provided invaluable lessons in handling dynamic web data, optimizing machine learning models, and ensuring real-time performance.
        </p>
        <p>
          The bot's ability to analyze live market data, perform sentiment analysis, calculate fundamental and technical indicators, and execute trades based on model predictions demonstrates a comprehensive approach to automated trading. By regularly updating its strategy and retraining its models, the bot remains effective and adaptable in a constantly changing market environment.
        </p>
        <p>
          Looking ahead, there is potential for further enhancements and scalability. The bot could be extended to trade other asset classes or integrated with more advanced trading strategies and analytics tools. Features such as automated portfolio rebalancing, risk management, and real-time alerts could also be added to improve its functionality and user experience.
        </p>
        <p>
          Overall, this project underscores the intersection of technology and finance, illustrating how automation and AI can revolutionize trading strategies and financial decision-making.
        </p>
      </>
    )
  }
];

const AIStockSentimentBot = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default AIStockSentimentBot;
