import React, { useContext, useState } from 'react';
import { XPContext } from './XPProvider';
import './XPBar.css';

const XPBar = () => {
  const { xp, maxXP } = useContext(XPContext);
  const [isMaxedOut, setIsMaxedOut] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const progress = (xp / maxXP) * 100;

  const handleClick = () => {
    if (xp >= maxXP) {
      setIsMaxedOut(true);
      setShowPopup(true);
      // Trigger the event to add the hat here
      const event = new CustomEvent('maxXPReached');
      window.dispatchEvent(event);
      // Hide popup after 3 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }
  };

  return (
    <div className="xp-bar-wrapper">
      {!isMaxedOut && (
        <div className="xp-bar-container" onClick={handleClick}>
          <div className="xp-bar" style={{ width: `${progress}%` }}>
            {xp >= maxXP && <div className="xp-bar-click-me">Click Me!</div>}
          </div>
          {xp < maxXP && <div className="xp-text">{xp}/{maxXP} XP</div>}
        </div>
      )}
      {showPopup && <div className="popup-message">Thank you for reading everything, in return, you now get a party hat!</div>}
    </div>
  );
};

export default XPBar;
