import * as THREE from 'three';

export const addSidewalk = (scene) => {
  const sidewalkGeometry = new THREE.PlaneGeometry(30, 3);
  const sidewalkMaterial = new THREE.MeshBasicMaterial({ color: 0x808080 });
  const sidewalk = new THREE.Mesh(sidewalkGeometry, sidewalkMaterial);
  sidewalk.position.set(0, 0.01, 0); // Ensure the y position is above the ground
  sidewalk.rotation.x = -Math.PI / 2;
  scene.add(sidewalk);
};
