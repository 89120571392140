import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import BuildingProvider from './BuildingContext';
import XPProvider from './XPProvider';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <XPProvider>
      <BuildingProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </BuildingProvider>
    </XPProvider>
  </React.StrictMode>
);
