import React, { useContext } from 'react';
import ProjectLayout from '../ProjectLayout';
import { XPContext } from '../XPProvider'; // Import XPContext
import visualization1 from '../images/IMG_5728.jpg';


const sections = [
  {
    id: 'description',
    title: 'Description',
    content: (
      <>
        <h1>AI-Powered Wearable Assistant</h1>
        <p>
          This project is an innovative AI-powered wearable assistant, built on a Raspberry Pi, that seamlessly integrates advanced functionalities into a user-friendly device. Leveraging a combination of voice recognition, image processing, and cloud-based AI services, this assistant provides a comprehensive solution for everyday tasks and personal management.
        </p>
        <p>
          Designed to enhance user interaction through multiple input modes, including voice commands, text, and images, users can interact with the device by speaking commands, typing queries, or taking pictures. This flexibility makes it an ideal tool for a wide range of applications, from personal health management to smart home integration.
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', margin: '20px 0' }}>
            <img src={visualization1} alt="AI smart glasses breakdown" style={{ width: '30%', height: 'auto' }} />
          </div>
        <p>
          One of the standout features of this assistant is its ability to analyze food items from images and calculate their nutritional macros. By simply taking a picture of a meal, the device can provide detailed information on calories, fats, carbohydrates, and proteins. This feature is particularly beneficial for individuals focused on maintaining a healthy diet and tracking their nutritional intake.
        </p>
        <p>
          In addition to nutritional analysis, the wearable assistant offers real-time weather updates, ensuring users are always prepared for the day ahead. It also integrates with Google Calendar to manage and display upcoming events, keeping users organized and on schedule.
        </p>
        <p>
          Equipped with a built-in camera and video recording capabilities, the assistant allows users to capture photos and videos hands-free. Advanced functionalities include saving video clips on command and recognizing text from captured images to provide relevant information or responses.
        </p>
        <p>
          For task management, the device features a robust to-do list system where users can add, mark, and delete tasks through simple voice commands. This ensures that users can stay productive and on top of their responsibilities without needing to manually interact with a device.
        </p>
        <p>
          Built on a foundation of powerful technologies, including Python, OpenCV, Tesseract OCR, and various APIs from Google and OpenAI, this assistant performs complex tasks efficiently and accurately. The use of a Raspberry Pi as the hardware platform ensures a compact, portable, and versatile solution that can be easily integrated into daily life.
        </p>
        <p>
          Overall, this AI-powered wearable assistant is a multifunctional device designed to simplify everyday tasks, enhance productivity, and support a healthy lifestyle through advanced technological integration.
        </p>
      </>
    )
  },
  {
    id: 'features',
    title: 'Features',
    content: (
      <>
       <p>
          The AI-powered wearable assistant is packed with a variety of advanced features designed to enhance user interaction and productivity. Utilizing Google’s Speech Recognition API, the device understands and executes spoken commands, enabling hands-free operation. It captures images using a built-in camera, processing them to extract meaningful information such as identifying food items and calculating their nutritional values. This nutritional analysis feature provides detailed information, including calories, fats, carbohydrates, and proteins, by simply taking a picture of a meal.
        </p>
        <p>
          Real-time weather updates are fetched from online sources and displayed on the device, ensuring users are always prepared for the day ahead. The assistant also integrates seamlessly with Google Calendar to manage and display upcoming events, helping users stay organized and on schedule. Equipped with photo and video capture capabilities, users can take photos and record videos hands-free, with advanced functionalities like saving video clips on command and recognizing text from captured images using Tesseract OCR.
        </p>
        <p>
          The device features a robust to-do list system where users can add, mark, and delete tasks through simple voice commands, ensuring they stay productive without manually interacting with the device. It also supports smart home integration, acting as a centralized control hub for connecting to and controlling smart home devices. The customizable OLED display updates with various information such as time, date, weather, and reminders, providing important data at a glance.
        </p>
        <p>
          For improved accessibility and communication, the assistant converts spoken words into text and displays subtitles on the screen. Users can set reminders for specific dates and times, ensuring important tasks and events are not forgotten. The device’s internet connectivity allows it to access online resources, providing information on demand such as weather updates and internet searches.
        </p>
        <p>
          These features combine to create a versatile and powerful assistant that enhances productivity, supports a healthy lifestyle, and simplifies everyday tasks through advanced technological integration.
        </p>
      </>
    )
  },
  {
    id: 'technologyUsed',
    title: 'Technology Used',
    content: (
      <>
        <p>
          The AI-powered wearable assistant is built on a robust foundation of both hardware and software technologies, ensuring reliable and efficient performance.
        </p>
        <p>
          <strong>Hardware:</strong>
        </p>
        <ul>
          <li><strong>Raspberry Pi</strong>: Serves as the central processing unit, providing a compact, portable, and versatile platform for the device.</li>
          <li><strong>Camera Module</strong>: Used for capturing photos and videos, enabling image-based queries and video recording functionalities.</li>
          <li><strong>OLED Display</strong>: Displays various information, including time, date, weather, reminders, and subtitles, ensuring important data is always accessible.</li>
        </ul>
        <p>
          <strong>Software:</strong>
        </p>
        <ul>
          <li><strong>Python</strong>: The primary programming language used for developing the software components of the device.</li>
          <li><strong>OpenCV</strong>: A powerful library for image processing, enabling the device to analyze images and recognize food items.</li>
          <li><strong>Tesseract OCR</strong>: Optical character recognition engine used to extract text from captured images.</li>
          <li><strong>Google Speech Recognition API</strong>: Converts spoken commands into text, enabling voice command functionalities.</li>
          <li><strong>Google Calendar API</strong>: Integrates with Google Calendar to manage and display upcoming events.</li>
          <li><strong>Google API Client</strong>: Facilitates communication with various Google services, such as calendar and weather.</li>
          <li><strong>Pytesseract</strong>: Python wrapper for Tesseract OCR, enabling text recognition from images.</li>
          <li><strong>PIL (Python Imaging Library)</strong>: Used for image manipulation and processing.</li>
          <li><strong>Wolfram Alpha API</strong>: Provides answers to general queries by accessing Wolfram Alpha’s computational knowledge engine.</li>
          <li><strong>OpenAI API</strong>: Utilizes GPT-4 for processing complex queries and generating detailed responses.</li>
          <li><strong>Requests</strong>: A simple HTTP library for making API requests to online services.</li>
          <li><strong>Pytz</strong>: Timezone library used for managing date and time data.</li>
          <li><strong>Smtplib and Email</strong>: Standard libraries for sending emails with or without attachments.</li>
          <li><strong>Twilio API</strong>: Enables sending SMS messages for notification purposes.</li>
          <li><strong>FuzzyWuzzy</strong>: Library for string matching, used to improve the accuracy of recognizing commands and queries.</li>
          <li><strong>ICalendar</strong>: Library for parsing and managing iCalendar files.</li>
          <li><strong>SpeechRecognition Library</strong>: Facilitates the capture and processing of audio input for voice commands.</li>
          <li><strong>Threading</strong>: Used to manage concurrent tasks and ensure the device operates smoothly without lag.</li>
        </ul>
        <p>
          This combination of hardware and software technologies allows the wearable assistant to perform a wide range of tasks efficiently and accurately, making it a powerful tool for personal management and daily assistance.
        </p>
      </>
    )
  },
  {
    id: 'challenges',
    title: 'Challenges',
    content: (
      <>
        <p>
          Developing the AI-powered wearable assistant presented several technical and logistical challenges, requiring innovative solutions and persistent effort.
        </p>
        <p>
          <strong>Voice Recognition Accuracy:</strong> Ensuring accurate voice recognition in various environments was challenging due to background noise and different accents. Extensive tuning of the Google Speech Recognition API and implementing noise reduction techniques were required to mitigate this.
        </p>
        <p>
          <strong>Image Processing and Nutritional Analysis:</strong> Recognizing and analyzing food items from images accurately was difficult due to variability in lighting conditions, angles, and image quality. Integrating OpenCV with Tesseract OCR improved text recognition from images, but additional machine learning models and comprehensive food databases were necessary for accurate nutritional information.
        </p>
        <p>
          <strong>Real-Time Performance:</strong> Achieving real-time performance for tasks such as weather updates, calendar synchronization, and task management required optimizing the code and efficient use of the Raspberry Pi’s limited processing power. Multi-threading allowed concurrent task execution, preventing delays and ensuring a smooth user experience.
        </p>
        <p>
          <strong>User Interaction and Command Recognition:</strong> Developing a natural and intuitive user interaction system was challenging as commands needed to be recognized accurately even when phrased differently. Implementing the FuzzyWuzzy library for string matching improved command recognition accuracy.
        </p>
        <p>
          <strong>Integration with Multiple APIs:</strong> Seamlessly integrating various APIs from Google, OpenAI, Wolfram Alpha, and others required careful handling of API responses, error management, and ensuring data privacy. Each API has its own limitations and rate limits, which had to be managed for a consistent user experience.
        </p>
        <p>
          <strong>Hardware Constraints:</strong> The limited processing power and memory of the Raspberry Pi posed significant challenges, especially for resource-intensive tasks like image processing and machine learning. Efficient coding practices, memory management, and leveraging cloud-based services for heavy processing helped overcome these limitations.
        </p>
        <p>
          <strong>Ensuring Data Privacy and Security:</strong> Handling sensitive data such as personal calendar events, nutritional information, and voice recordings required robust security measures, including secure API authentication, encrypted data storage, and compliance with data privacy regulations.
        </p>
        <p>
          Despite these challenges, the development team successfully created a functional, reliable, and versatile wearable assistant by leveraging advanced technologies and innovative solutions.
        </p>
      </>
    )
  },
  {
    id: 'conclusion',
    title: 'Conclusion',
    content: (
      <>
        <p>
          The AI-powered wearable assistant is a comprehensive and innovative solution that integrates advanced technologies to simplify everyday tasks and enhance personal productivity. Built on the versatile Raspberry Pi platform, it combines voice recognition, image processing, and cloud-based AI services to offer a range of functionalities, from nutritional analysis and real-time weather updates to task management and smart home integration.
        </p>
        <p>
          The development process presented numerous challenges, including ensuring accurate voice recognition, achieving real-time performance, and managing hardware constraints. Through persistent effort and innovative solutions, the development team successfully overcame these obstacles, resulting in a powerful and user-friendly device.
        </p>
        <p>
          Looking ahead, the next steps for this project include further refining the accuracy of voice and image recognition, expanding the range of supported commands and functionalities, and enhancing the integration with additional smart home devices and online services. Continued improvements in the user interface and interaction models will also be prioritized to ensure an even more seamless and intuitive user experience.
        </p>
        <p>
          Overall, this project demonstrates the potential of combining cutting-edge technologies to create a multifunctional assistant that supports a healthy lifestyle, boosts productivity, and simplifies daily life. As technology continues to evolve, the capabilities of this wearable assistant will only grow, offering even more value to its users.
        </p>
      </>
    )
  }
];

const AISmartGlasses = ({ onUnlockMovement }) => {
  const { addXP } = useContext(XPContext); // Use XPContext to get addXP function

  // Assuming you need to call addXP somewhere in the project, for example:
  const handleAddXP = () => {
    addXP(10); // Add XP points when certain actions are performed
  };

  return (
    <ProjectLayout sections={sections} onUnlockMovement={onUnlockMovement} />
  );
};

export default AISmartGlasses; // Updated export statement
