import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { XPContext } from './XPProvider';
import { BuildingContext } from './BuildingContext';
import './project.css';

const ProjectLayout = ({ sections, onUnlockMovement }) => {
  const navigate = useNavigate();
  const { addXP } = useContext(XPContext);
  const { building } = useContext(BuildingContext);
  const [visitedSections, setVisitedSections] = useState([]);
  const observerRef = useRef(null);
  const [currentFloor, setCurrentFloor] = useState(1);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isOverviewCollapsed, setIsOverviewCollapsed] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(window.innerWidth > 768);
  
  const location = useLocation(); // Get the current location

  useEffect(() => {
    // Reset visitedSections when a new project is loaded
    const savedVisitedSections = JSON.parse(localStorage.getItem('visitedSections'));
    if (savedVisitedSections) {
      setVisitedSections(savedVisitedSections);
    } else {
      setVisitedSections([]);
    }
  }, [location.pathname]); // Depend on location.pathname to reset on project change

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !visitedSections.includes(entry.target.id)) {
            setVisitedSections((prevVisited) => {
              const newVisitedSections = [...prevVisited, entry.target.id];
              localStorage.setItem('visitedSections', JSON.stringify(newVisitedSections));
              return newVisitedSections;
            });
            addXP(1);
            showPopup(entry.target);
            window.history.replaceState(null, null, `#${entry.target.id}`);
          }
        });
      },
      { threshold: 0.5 }
    );

    sections.forEach((section) => {
      const sectionElement = document.getElementById(section.id);
      if (sectionElement) {
        observerRef.current.observe(sectionElement);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [addXP, visitedSections, sections]);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
        if (!visitedSections.includes(hash)) {
          setVisitedSections((prevVisited) => {
            const newVisitedSections = [...prevVisited, hash];
            localStorage.setItem('visitedSections', JSON.stringify(newVisitedSections));
            return newVisitedSections;
          });
          addXP(1);
          showPopup(section);
        }
      }
    }
  }, [location.hash, visitedSections, addXP]);

  useEffect(() => {
    const savedVisitedSections = JSON.parse(localStorage.getItem('visitedSections'));
    if (savedVisitedSections) {
      setVisitedSections(savedVisitedSections);
    }
  }, []);

  useEffect(() => {
    const floors = [1, 2, 3, 2];
    let index = 0;
    const interval = setInterval(() => {
      setCurrentFloor(floors[index]);
      index = (index + 1) % floors.length;
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setIsMobileView(isMobile);
      setIsOverviewCollapsed(isMobile);
      setIsMenuOpen(!isMobile);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMobileView && isMenuOpen) {
        const sidebar = document.querySelector('.sidebar');
        const menuButton = document.querySelector('.collapse-button');
        if (sidebar && !sidebar.contains(event.target) && !menuButton.contains(event.target)) {
          setIsMenuOpen(false);
          setIsOverviewCollapsed(true);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isMobileView, isMenuOpen]);

  const showPopup = (section) => {
    const popup = document.createElement('div');
    popup.textContent = '+1';
    popup.className = 'xp-popup';
    popup.style.position = 'absolute';
    popup.style.color = '#00ff00';
    popup.style.fontWeight = 'bold';
    popup.style.top = `${section.getBoundingClientRect().top + window.scrollY}px`;
    popup.style.left = `${section.getBoundingClientRect().left + window.scrollX + section.offsetWidth / 2}px`;
    document.body.appendChild(popup);

    setTimeout(() => {
      popup.style.transition = 'opacity 0.5s';
      popup.style.opacity = '0';
      setTimeout(() => {
        popup.remove();
      }, 500);
    }, 500);
  };

  const handleLinkClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      if (!visitedSections.includes(sectionId)) {
        setVisitedSections((prevVisited) => [...prevVisited, sectionId]);
        addXP(1);
        showPopup(section);
      }
      window.history.replaceState(null, null, `#${sectionId}`);
    }
  };

  const handleExit = () => {
    const exitPosition = building?.exit || { x: 0, z: 0 };
    onUnlockMovement();
    navigate('/', { state: { resetPosition: true, exit: exitPosition } });
  };

  const handleElevatorClick = () => {
    const exitPosition = building?.exit || { x: 0, z: 0 };
    navigate('/lobby', { state: { resetPosition: true, building, exit: exitPosition } });
  };

  const toggleOverview = () => {
    if (isMobileView) {
      setIsOverviewCollapsed(!isOverviewCollapsed);
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (
    <div className="project-container">
      <div className={`sidebar ${isMobileView && isOverviewCollapsed ? 'collapsed' : ''} ${isMobileView && isMenuOpen ? 'open' : ''}`}>
        <h2>Overview</h2>
        <ul className="nav-links">
          {sections.map((section) => (
            <li
              key={section.id}
              className={visitedSections.includes(section.id) ? 'visited' : ''}
              onClick={() => handleLinkClick(section.id)}
            >
              {section.title}
            </li>
          ))}
        </ul>
        <button className="back-button" onClick={handleExit}>
          <div className="door-container">
            <div className="door-background"></div>
            <div className="door">
              <span className="door-text">Back to Cityscape</span>
            </div>
          </div>
        </button>
      </div>
      <div className={`main-content ${isMobileView && isMenuOpen ? 'with-sidebar' : ''}`}>
        {sections.map((section) => (
          <div key={section.id} id={section.id} className="section-container">
            <h2>{section.title}</h2>
            <div>{section.content}</div>
          </div>
        ))}
      </div>
      <button className="elevator-overlay-button" onClick={handleElevatorClick}>
        <div className="small-elevator">
          <div className="floor-indicator">{currentFloor}</div>
          <div className="elevator-doors">
            <div className="door-left"></div>
            <div className="door-right"></div>
          </div>
        </div>
      </button>
      <button className="collapse-button" onClick={toggleOverview}>
        <div className="door-container">
          <div className="door-background"></div>
          <div className="door">
            <span className="door-text">{isOverviewCollapsed ? 'Open Overview' : 'Collapse Overview'}</span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ProjectLayout;
